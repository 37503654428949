/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
 :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: 'left';
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
  /* width:100% */
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: 'left';
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014   \A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: 'left';
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: 'left';
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: 'left';
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: 'left';
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-99 {
  width: 99% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.ml-05 {
  margin: 0.1rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mb-3-5,
.my-3-5 {
  margin-bottom: 1.25rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mt-5-5,
.my-5-5 {
  margin-top: 5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 6rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom : 7rem!important;
}

.mb-9 {
  margin-bottom : 9rem!important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-lembrete {
  color: #cc395d !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.db {
  display: block
}

.light_op_text {
  color: rgba(255, 255, 255, .5)
}

blockquote {
  border-left: 5px solid #ff6849!important;
  border: 1px solid rgba(120, 130, 140, .13)
}

p {
  line-height: 1.6
}

b {
  font-weight: 600
}

a:active,
a:focus,
a:hover {
  outline: 0;
  text-decoration: none
}

.clear {
  clear: both
}

.font-12 {
  font-size: 12px
}

.b-t {
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.b-b {
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.b-l {
  border-left: 1px solid rgba(120, 130, 140, .13)
}

.b-r {
  border-right: 1px solid rgba(120, 130, 140, .13)
}

.b-all {
  border: 1px solid rgba(120, 130, 140, .13)
}

.b-none {
  border: 0!important
}

.max-height {
  height: 310px;
  overflow: auto
}

.p-0 {
  padding: 0!important
}

.p-5 {
  padding: 5px!important
}

.p-10 {
  padding: 10px!important
}

.p-20 {
  padding: 20px!important
}
.p-10-20 {
  padding: 20px!important
}

.p-10-20 {
  padding: 20px 10px 20px 10px!important
}

.p-30 {
  padding: 30px!important
}

.p-40 {
  padding: 40px!important
}

.p-l-0 {
  padding-left: 0!important
}

.p-l-10 {
  padding-left: 10px!important
}

.p-l-20 {
  padding-left: 20px!important
}

.p-r-0 {
  padding-right: 0!important
}

.p-r-10 {
  padding-right: 10px!important
}

.p-r-20 {
  padding-right: 20px!important
}

.p-r-30 {
  padding-right: 30px!important
}

.p-r-40 {
  padding-right: 40px!important
}

.p-t-0 {
  padding-top: 0!important
}

.p-t-10 {
  padding-top: 10px!important
}

.p-t-20 {
  padding-top: 20px!important
}

.p-t-30 {
  padding-top: 30px!important
}

.p-b-0 {
  padding-bottom: 0!important
}

.p-b-10 {
  padding-bottom: 10px!important
}

.p-b-20 {
  padding-bottom: 20px!important
}

.p-b-30 {
  padding-bottom: 30px!important
}

.p-b-40 {
  padding-bottom: 40px!important
}

.m-0 {
  margin: 0!important
}

.m-l-5 {
  margin-left: 5px!important
}

.m-l-10 {
  margin-left: 10px!important
}

.m-l-15 {
  margin-left: 15px!important
}

.m-l-20 {
  margin-left: 20px!important
}

.m-l-30 {
  margin-left: 30px!important
}

.m-l-40 {
  margin-left: 40px!important
}

.m-r-5 {
  margin-right: 5px!important
}

.m-r-10 {
  margin-right: 10px!important
}

.m-r-15 {
  margin-right: 15px!important
}

.m-r-20 {
  margin-right: 20px!important
}

.m-r-30 {
  margin-right: 30px!important
}

.m-r-40 {
  margin-right: 40px!important
}

.m-r-60 {
  margin-right: 60px!important
}

.m-t-5 {
  margin-top: 5px!important
}

.m-t-0 {
  margin-top: 0!important
}

.m-t-10 {
  margin-top: 10px!important
}

.m-t-8 {
  margin-top: 8px!important
}

.m-t-15 {
  margin-top: 15px!important
}

.m-t-20 {
  margin-top: 20px!important
}

.m-t-30 {
  margin-top: 30px!important
}

.m-t-40 {
  margin-top: 40px!important
}

.m-b-0 {
  margin-bottom: 0!important
}

.m-b-5 {
  margin-bottom: 5px!important
}

.m-b-10 {
  margin-bottom: 10px!important
}

.m-b-15 {
  margin-bottom: 15px!important
}

.m-b-20 {
  margin-bottom: 20px!important
}

.m-b-30 {
  margin-bottom: 30px!important
}

.m-b-40 {
  margin-bottom: 40px!important
}

.vt {
  vertical-align: top
}

.vb {
  vertical-align: bottom
}

.font-bold {
  font-weight: 700
}

.font-normal {
  font-weight: 400
}

.font-light {
  font-weight: 300
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px
}

.b-0 {
  border: none!important
}

.vertical-middle {
  vertical-align: middle
}

.bx-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1)
}

.mx-box {
  max-height: 380px;
  min-height: 380px
}

.thumb-sm {
  height: 32px;
  width: 32px
}

.thumb-md {
  height: 48px;
  width: 48px
}

.thumb-lg {
  height: 88px;
  width: 88px
}

.txt-oflo {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.get-code {
  color: #2b2b2b;
  cursor: pointer;
  border-radius: 100%;
  background: #fff;
  padding: 4px 5px;
  font-size: 10px;
  margin: 0 5px;
  vertical-align: middle
}

.badge {
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 5px;
  font-size: 12px;
  margin-top: 1px;
  background-color: #fec107
}

.badge-xs {
  font-size: 9px
}

.badge-sm,
.badge-xs {
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px)
}

.badge-success {
  background-color: #00c292
}

.badge-info {
  background-color: #03a9f3
}

.badge-warning {
  background-color: #fec107
}

.badge-danger {
  background-color: #fb9678
}

.badge-purple {
  background-color: #9675ce
}

.badge-red {
  background-color: #fb3a3a
}

.badge-inverse {
  background-color: #4c5667
}

.notify {
  position: relative;
  margin-top: -30px
}

.notify .point {
  width: 6px;
  height: 6px;
  border-radius: 30px;
  background-color: #fb9678;
  position: absolute;
  right: -6px;
  top: -10px
}

.text-white {
  color: #fff!important
}

.text-danger {
  color: #fb9678!important
}

.text-muted {
  color: #8d9ea7!important
}

.text-warning {
  color: #fec107!important
}

.text-success {
  color: #00c292!important
}

.text-info {
  color: #03a9f3!important
}

.text-inverse {
  color: #4c5667!important
}

.text-blue {
  color: #02bec9!important
}

.text-purple {
  color: #9675ce!important
}

.text-primary {
  color: #ab8ce4!important
}

.text-megna {
  color: #01c0c8!important
}

.text-dark {
  color: #686868!important
}

.bg-primary {
  background-color: #ab8ce4!important
}

.bg-success {
  background-color: #00c292!important
}

.bg-info {
  background-color: #03a9f3!important
}

.bg-warning {
  background-color: #fec107!important
}

.bg-danger {
  background-color: #fb9678!important
}

.bg-theme {
  background-color: #ff6849!important
}

.bg-theme-dark {
  background-color: #4f5467!important
}

.bg-inverse {
  background-color: #4c5667!important
}

.bg-lembrete {
  background-color: #cc395d!important
}

.bg-purple {
  background-color: #9675ce!important
}

.bg-white {
  background-color: #fff!important
}

.label {
  letter-spacing: .05em;
  border-radius: 60px;
  padding: 4px 16px 3px;
  font-weight: 500
}

.label-rouded,
.label-rounded {
  border-radius: 60px;
  padding: 4px 16px 3px;
  font-weight: 500
}

.label-custom {
  background-color: #01c0c8
}

.label-success {
  background-color: #00c292
}

.label-info {
  background-color: #03a9f3
}

.label-warning {
  background-color: #fec107
}

.label-danger {
  background-color: #fb9678
}

.label-megna {
  background-color: #01c0c8
}

.label-primary {
  background-color: #ab8ce4
}

.label-purple {
  background-color: #9675ce
}

.label-red {
  background-color: #fb3a3a
}

.label-inverse {
  background-color: #4c5667
}

.label-white {
  background-color: #fff
}

.label-default {
  background-color: #e4e7ea
}

.dropdown-menu {
  border: 1px solid rgba(64, 210, 190, .20);
  border-radius: 10px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, .05)!important;
  -webkit-box-shadow: 0!important;
  -moz-box-shadow: 0!important;
  padding-bottom: 8px;
  margin-top: 0
}

.dropdown-menu>li>a {
  padding: 9px 20px
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
  background: #f7fafc
}

.navbar-top-links .progress {
  margin-bottom: 6px
}

label {
  font-weight: 500
}

.btn {
  border-radius: 0
}

.form-control {
  background-color: #fff;
  border: 1px solid #e4e7ea;
  border-radius: 10px;
  box-shadow: none;
  color: #565656;
  height: 38px;
  max-width: 100%;
  padding: 7px 12px;
  transition: all 300ms linear 0s
}

.form-control:focus {
  box-shadow: none;
  border-color: rgb(170, 226, 218);
  border-radius: 10px
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5
}

.input-lg {
  height: 44px;
  padding: 5px 10px;
  font-size: 18px
}

.bootstrap-tagsinput {
  border: 1px solid #e4e7ea;
  border-radius: 0;
  box-shadow: none;
  display: block;
  padding: 7px 12px
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
  padding: 9px 10px
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down,
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0
}

.input-group-btn .btn {
  padding: 8px 12px
}

.form-horizontal .form-group {
  margin-left: -7.5px;
  margin-right: -7.5px;
  margin-bottom: 25px
}

.form-group {
  margin-bottom: 25px
}

.list-group-item,
.list-group-item:first-child,
.list-group-item:last-child {
  border-radius: 0;
  border-color: rgba(120, 130, 140, .13)
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  background: #03a9f3;
  border-color: #03a9f3
}

.list-task .list-group-item,
.list-task .list-group-item:first-child {
  border-radius: 0;
  border: 0
}

.list-task .list-group-item:last-child {
  border-radius: 0;
  border: 0
}

.media {
  border: 1px solid rgba(120, 130, 140, .13);
  margin-bottom: 10px;
  padding: 15px
}

.media .media-heading {
  font-weight: 500
}

.well,
pre {
  background: #fff;
  border-radius: 0
}

.nav-tabs>li>a {
  border-radius: 0;
  color: #2b2b2b
}

.nav-tabs>li>a:focus,
.nav-tabs>li>a:hover {
  background: #fff
}

.modal-content {
  border: none!important;
  border-radius: 10px;
  box-shadow: 0px 3px 5px 0px rgba(166, 191, 187, 0.8)
}

.alert {
  border-radius: 0
}

.carousel-control {
  width: 8%
}

.carousel-control span {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  font-size: 30px
}

.popover {
  border-radius: 0
}

.popover-title {
  padding: 5px 14px
}

.btn-group-vertical>.btn:first-child:not(:last-child),
.btn-group-vertical>.btn:last-child:not(:first-child) {
  border-radius: 0
}

.table-responsive {
  overflow-y: hidden
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px
}

.pagination>li>a,
.pagination>li>span {
  color: #2b2b2b
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  background-color: #e4e7ea
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  border-radius: 0
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background-color: #40D2BE;
  border-color: #40D2BE
}

.pager li>a,
.pager li>span {
  border-radius: 0;
  color: #2b2b2b
}

.table-box {
  display: table;
  width: 100%
}

.cell {
  display: table-cell;
  vertical-align: middle
}

.jqstooltip {
  width: auto!important;
  height: auto!important
}

#wrapper {
  width: 100%;
  margin: 0 auto;
  box-shadow: 1px 0 50px rgba(0, 0, 0, .2)
}

#page-wrapper {
  padding: 0 0 40px;
  min-height: 568px;
  background: #edf1f5
}

.footer {
  position: absolute;
  width: 100%;
  height: 60px;
  line-height: 60px;
  /* Vertically center the text there */
  background-color: #f5f5f5;
}

.bg-title {
  border-bottom: 1px solid #e4e7ea;
  overflow: hidden;
  padding: 15px 15px 10px;
  margin-bottom: 25px;
  margin-right: -25.5px
}

.bg-title h4 {
  color: rgba(0, 0, 0, .5);
  font-weight: 600;
  margin-top: 6px
}

.bg-title .breadcrumb {
  background: 0 0;
  margin-bottom: 0;
  float: right;
  padding: 0;
  margin-top: 8px
}

.bg-title .breadcrumb a {
  color: rgba(0, 0, 0, .5)
}

.bg-title .breadcrumb a:hover {
  color: #000
}

.bg-title .breadcrumb .active {
  color: #ff6849
}

.logo b {
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 60px;
  text-align: center
}

.logo i {
  color: #fff
}

.top-left-part {
  width: 220px;
  float: left
}

.top-left-part a {
  color: #fff;
  font-size: 18px;
  padding-left: 0;
  text-transform: uppercase
}

.navbar-header {
  width: 100%;
  background: #3c4451;
  border: 0
}

.navbar-default {
  border: 0
}

.navbar-top-links {
  margin-right: 0
}

.navbar-top-links .badge {
  position: absolute;
  right: 6px;
  top: 15px
}

.navbar-top-links>li {
  float: 'left';
  border-radius: 10px
}

.navbar-top-links>li>a {
  color: #fff;
  padding: 0 12px;
  line-height: 60px;
  min-height: 60px;
  border-radius: 10px
}

.navbar-top-links>li>a:hover {
  background: rgba(0, 0, 0, .1);
  border-radius: 10px
}

.navbar-top-links>li>a:focus {
  background: rgba(0, 0, 0, 0)
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background: rgba(255, 255, 255, .2);
  border-radius: 10px
}

.navbar-top-links .dropdown-menu li {
  display: block;
  border-radius: 10px
}

.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
  border-radius: 10px
}

.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
  border-radius: 10px
}

.navbar-top-links .dropdown-alerts,
.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks {
  width: 310px;
  min-width: 0;
  border-radius: 10px
}

.navbar-top-links .dropdown-messages {
  margin-left: 5px;
  border-radius: 10px
}

.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
  border-radius: 10px
}

.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
  border-radius: 10px
}

.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
  border-radius: 10px
}

.navbar-header .navbar-toggle {
  float: none;
  padding: 0 15px;
  line-height: 60px;
  border: 0;
  color: rgba(255, 255, 255, .5);
  margin: 0;
  display: inline-block;
  border-radius: 0
}

.navbar-header .navbar-toggle:focus,
.navbar-header .navbar-toggle:hover {
  background: rgba(0, 0, 0, .3);
  color: #fff
}

.app-search {
  position: relative;
  margin: 0
}

.app-search a {
  position: absolute;
  top: 20px;
  right: 10px;
  color: #4c5667
}

.app-search .form-control,
.app-search .form-control:focus {
  border: none;
  font-size: 13px;
  color: #4c5667;
  padding-left: 20px;
  padding-right: 40px;
  background: rgba(255, 255, 255, .9);
  box-shadow: none;
  height: 30px;
  font-weight: 600;
  width: 180px;
  display: inline-block;
  line-height: 30px;
  margin-top: 15px;
  border-radius: 10px;
  transition: .5s ease-out
}

.app-search .form-control::-moz-placeholder {
  color: #4c5667;
  opacity: .5;
  border-radius: 10px
}

.app-search .form-control::-webkit-input-placeholder {
  color: #4c5667;
  opacity: .5;
  border-radius: 10px
}

.app-search .form-control::-ms-placeholder {
  color: #4c5667;
  opacity: .5;
  border-radius: 10px
}

.nav-small-cap {
  color: #a6afbb;
  cursor: default;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: .035em;
  padding: 12px 15px!important;
  pointer-events: none;
  margin: 20px 0 0 -15px;
  display: none!important
}

.profile-pic {
  padding: 0 20px;
  line-height: 50px
}

.profile-pic img {
  margin-right: 10px
}

.drop-title {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  color: #2b2b2b;
  font-size: 15px;
  font-weight: 600;
  padding: 11px 20px 15px
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s
}

.btn-rounded {
  border-radius: 60px
}

.btn-custom,
.btn-custom.disabled {
  background: #ff6849;
  border: 1px solid #ff6849;
  color: #fff
}

.btn-custom.disabled.focus,
.btn-custom.disabled:focus,
.btn-custom.disabled:hover,
.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
  background: #ff6849;
  opacity: .8;
  color: #fff;
  border: 1px solid #ff6849
}

.btn-primary,
.btn-primary.disabled {
  background: #ab8ce4;
  border: 1px solid #ab8ce4
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  background: #ab8ce4;
  opacity: .8;
  border: 1px solid #ab8ce4
}

.btn-success,
.btn-success.disabled {
  background: #9ce6dd;
  border: 0px solid #40D2BE
}

.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  background: rgba(64, 210, 190, 0.8);
  box-shadow: 0px 3px 1px 0px rgba(166, 191, 187, 0.8)
}

.btn-info,
.btn-info.disabled {
  background: #03a9f3;
  border: 1px solid #03a9f3
}

.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
  background: #03a9f3;
  opacity: .8;
  border: 1px solid #03a9f3
}

.btn-warning,
.btn-warning.disabled {
  background: #fec107;
  border: 1px solid #fec107
}

.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
  background: #fec107;
  opacity: .8;
  border: 1px solid #fec107
}

.btn-danger,
.btn-danger.disabled {
  background: #fb9678;
  border: 1px solid #fb9678
}

.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
  background: #fb9678;
  opacity: .8;
  border: 1px solid #fb9678
}

.btn-default,
.btn-default.disabled {
  background: #e4e7ea;
  border: 1px solid #e4e7ea
}

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default.focus,
.btn-default:focus,
.btn-default:hover {
  background: #e4e7ea;
  box-shadow: 0px 3px 1px 0px rgba(166, 191, 187, 0.8)
}

.btn-default.btn-outline {
  background-color: #fff
}

.btn-default.btn-outline.focus,
.btn-default.btn-outline:focus,
.btn-default.btn-outline:hover {
  background: #40D2BE;
  box-shadow: 0px 3px 1px 0px rgba(166, 191, 187, 0.8)
}

.btn-primary.btn-outline {
  color: #ab8ce4;
  background-color: #fff
}

.btn-primary.btn-outline.focus,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:hover {
  background: #ab8ce4;
  color: #fff
}

.btn-success.btn-outline {
  color: #00c292;
  background-color: transparent
}

.btn-success.btn-outline.focus,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:hover {
  background: #00c292;
  color: #fff
}

.btn-info.btn-outline {
  color: #03a9f3;
  background-color: transparent
}

.btn-info.btn-outline.focus,
.btn-info.btn-outline:focus,
.btn-info.btn-outline:hover {
  background: #03a9f3;
  color: #fff
}

.btn-warning.btn-outline {
  color: #fec107;
  background-color: transparent
}

.btn-warning.btn-outline.focus,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:hover {
  background: #fec107;
  color: #fff
}

.btn-danger.btn-outline {
  color: #fb9678;
  background-color: transparent
}

.btn-danger.btn-outline.focus,
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:hover {
  background: #fb9678;
  color: #fff
}

.button-box .btn {
  margin: 0 8px 8px 0
}

.btn-danger.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-warning.btn-outline:hover {
  color: #fff
}

.btn-label {
  background: rgba(0, 0, 0, .05);
  display: inline-block;
  margin: -6px 12px -6px -14px;
  padding: 7px 15px
}

.btn-facebook {
  color: #fff!important;
  background-color: #3b5998!important
}

.btn-twitter {
  color: #fff!important;
  background-color: #55acee!important
}

.btn-linkedin {
  color: #fff!important;
  background-color: #007bb6!important
}

.btn-dribbble {
  color: #fff!important;
  background-color: #ea4c89!important
}

.btn-googleplus {
  color: #fff!important;
  background-color: #dd4b39!important
}

.btn-instagram {
  color: #fff!important;
  background-color: #3f729b!important
}

.btn-pinterest {
  color: #fff!important;
  background-color: #cb2027!important
}

.btn-dropbox {
  color: #fff!important;
  background-color: #007ee5!important
}

.btn-flickr {
  color: #fff!important;
  background-color: #ff0084!important
}

.btn-tumblr {
  color: #fff!important;
  background-color: #32506d!important
}

.btn-skype {
  color: #fff!important;
  background-color: #00aff0!important
}

.btn-youtube {
  color: #fff!important;
  background-color: #b00!important
}

.btn-github {
  color: #fff!important;
  background-color: #171515!important
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary:focus,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  background-color: #ab8ce4;
  border: 1px solid #ab8ce4
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.btn-success:focus,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
  background-color: rgba(64, 210, 190, 0.8);
  border: 1px solid rgba(64, 210, 190, 0.8)
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.btn-info:focus,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
  background-color: #03a9f3;
  border: 1px solid #03a9f3
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning:focus,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
  background-color: #fec107;
  border: 1px solid #fec107
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger:focus,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
  background-color: #fb9678;
  border: 1px solid #fb9678
}

.btn-inverse,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open>.dropdown-toggle.btn-inverse {
  background-color: #4c5667;
  border: 1px solid #4c5667;
  color: #fff
}

.chat {
  margin: 0;
  padding: 0;
  list-style: none
}

.chat li {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted rgba(120, 130, 140, .13)
}

.chat li.left .chat-body {
  margin-left: 60px
}

.chat li.right .chat-body {
  margin-right: 60px
}

.chat li .chat-body p {
  margin: 0
}

.chat .glyphicon,
.panel .slidedown .glyphicon {
  margin-right: 5px
}

.chat-panel .panel-body {
  height: 350px;
  overflow-y: scroll
}

.login-panel {
  margin-top: 25%
}

.flot-chart {
  display: block;
  height: 400px
}

.flot-chart-content {
  width: 100%;
  height: 100%
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_desc_disabled {
  background: 0 0
}

table.dataTable thead .sorting_asc:after {
  content: "\F0DE";
  float: right;
  font-family: fontawesome
}

table.dataTable thead .sorting_desc:after {
  content: "\F0DD";
  float: right;
  font-family: fontawesome
}

table.dataTable thead .sorting:after {
  content: "\F0DC";
  float: right;
  font-family: fontawesome;
  color: rgba(50, 50, 50, .5)
}

.btn-circle {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33
}

.show-grid [class^=col-] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid rgba(120, 130, 140, .13);
  background-color: #f7fafc
}

.show-grid {
  margin: 15px 0
}

.huge {
  font-size: 40px
}

.white-box {
  background: #fff;
  padding: 25px;
  margin-bottom: 15px
}

.white-box .box-title {
  border-radius: 10px;
  margin: 0 0 12px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  font-size: 14px;
}

.panel {
  border-radius: 0;
  margin-bottom: 15px;
  border: 0
}

.panel .panel-heading {
  border-radius: 0;
  font-weight: 600;
  text-transform: uppercase;
  padding: 20px 25px
}

.panel .panel-heading .panel-title {
  font-size: 14px;
  color: #2b2b2b
}

.panel .panel-heading a i {
  font-size: 12px;
  margin-left: 8px
}

.panel .panel-action {
  float: right
}

.panel .panel-action a {
  opacity: .5
}

.panel .panel-action a:hover {
  opacity: 1
}

.panel .panel-body {
  padding: 25px
}

.panel .panel-body:first-child h3 {
  margin-top: 0;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  text-transform: uppercase
}

.panel .panel-footer {
  background: #fff;
  border-radius: 0;
  padding: 20px 25px
}

.panel-green,
.panel-success {
  border-color: #00c292
}

.panel-green .panel-heading,
.panel-success .panel-heading {
  border-color: #00c292;
  color: #fff;
  background-color: #00c292
}

.panel-green .panel-heading a,
.panel-success .panel-heading a {
  color: #fff
}

.panel-green .panel-heading a:hover,
.panel-success .panel-heading a:hover {
  color: rgba(255, 255, 255, .5)
}

.panel-green a,
.panel-success a {
  color: #00c292
}

.panel-green a:hover,
.panel-success a:hover {
  color: #007658
}

.panel-black,
.panel-inverse {
  border-color: #4c5667
}

.panel-black .panel-heading,
.panel-inverse .panel-heading {
  border-color: #4c5667;
  color: #fff;
  background-color: #4c5667
}

.panel-black .panel-heading a,
.panel-inverse .panel-heading a {
  color: #fff
}

.panel-black .panel-heading a:hover,
.panel-inverse .panel-heading a:hover {
  color: rgba(255, 255, 255, .5)
}

.panel-black a,
.panel-inverse a {
  color: #4c5667
}

.panel-black a:hover,
.panel-inverse a:hover {
  color: #2c313b
}

.panel-darkblue,
.panel-primary {
  border-color: #ab8ce4
}

.panel-darkblue .panel-heading,
.panel-primary .panel-heading {
  border-color: #ab8ce4;
  color: #fff;
  background-color: #ab8ce4
}

.panel-darkblue .panel-heading a,
.panel-primary .panel-heading a {
  color: #fff
}

.panel-darkblue .panel-heading a:hover,
.panel-primary .panel-heading a:hover {
  color: rgba(255, 255, 255, .5)
}

.panel-darkblue a,
.panel-primary a {
  color: #ab8ce4
}

.panel-darkblue a:hover,
.panel-primary a:hover {
  color: #7e4ed5
}

.panel-blue,
.panel-info {
  border-color: #03a9f3
}

.panel-blue .panel-heading,
.panel-info .panel-heading {
  border-color: #03a9f3;
  color: #fff;
  background-color: #03a9f3
}

.panel-blue .panel-heading a,
.panel-info .panel-heading a {
  color: #fff
}

.panel-blue .panel-heading a:hover,
.panel-info .panel-heading a:hover {
  color: rgba(255, 255, 255, .5)
}

.panel-blue a,
.panel-info a {
  color: #03a9f3
}

.panel-blue a:hover,
.panel-info a:hover {
  color: #0274a7
}

.panel-danger,
.panel-red {
  border-color: #fb9678
}

.panel-danger .panel-heading,
.panel-red .panel-heading {
  border-color: #fb9678;
  color: #fff;
  background-color: #fb9678
}

.panel-danger .panel-heading a,
.panel-red .panel-heading a {
  color: #fff
}

.panel-danger .panel-heading a:hover,
.panel-red .panel-heading a:hover {
  color: rgba(255, 255, 255, .5)
}

.panel-danger a,
.panel-red a {
  color: #fb9678
}

.panel-danger a:hover,
.panel-red a:hover {
  color: #f95c2e
}

.panel-warning,
.panel-yellow {
  border-color: #fec107
}

.panel-warning .panel-heading,
.panel-yellow .panel-heading {
  border-color: #fec107;
  color: #fff;
  background-color: #fec107
}

.panel-warning .panel-heading a,
.panel-yellow .panel-heading a {
  color: #fff
}

.panel-warning .panel-heading a:hover,
.panel-yellow .panel-heading a:hover {
  color: rgba(255, 255, 255, .5)
}

.panel-warning a,
.panel-yellow a {
  color: #fec107
}

.panel-warning a:hover,
.panel-yellow a:hover {
  color: #b88b01
}

.panel-default,
.panel-white {
  border-color: rgba(120, 130, 140, .13)
}

.panel-default .panel-heading,
.panel-white .panel-heading {
  color: #2b2b2b;
  background-color: #fff;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.panel-default .panel-body,
.panel-white .panel-body {
  color: #2b2b2b
}

.panel-default .panel-action a,
.panel-white .panel-action a {
  color: #2b2b2b;
  opacity: .5
}

.panel-default .panel-action a:hover,
.panel-white .panel-action a:hover {
  opacity: 1;
  color: #2b2b2b
}

.panel-default .panel-footer,
.panel-white .panel-footer {
  background: #fff;
  color: #2b2b2b;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-info {
  border-color: #03a9f3
}

.full-panel-info .panel-heading {
  border-color: #03a9f3;
  color: #fff;
  background-color: #03a9f3;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-info .panel-body {
  background: #03a9f3;
  color: #fff
}

.full-panel-info .panel-footer {
  background: #03a9f3;
  color: #fff;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-info a {
  color: #03a9f3
}

.full-panel-info a:hover {
  color: #0274a7
}

.full-panel-warning {
  border-color: #fec107
}

.full-panel-warning .panel-heading {
  border-color: #fec107;
  color: #fff;
  background-color: #fec107;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-warning .panel-body {
  background: #fec107;
  color: #fff
}

.full-panel-warning .panel-footer {
  background: #fec107;
  color: #fff;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-warning a {
  color: #fec107
}

.full-panel-warning a:hover {
  color: #b88b01
}

.full-panel-success {
  border-color: #00c292
}

.full-panel-success .panel-heading {
  border-color: #00c292;
  color: #fff;
  background-color: #00c292;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-success .panel-body {
  background: #00c292;
  color: #fff
}

.full-panel-success .panel-footer {
  background: #00c292;
  color: #fff;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-success a {
  color: #00c292
}

.full-panel-success a:hover {
  color: #007658
}

.full-panel-purple {
  border-color: #9675ce
}

.full-panel-purple .panel-heading {
  color: #fff;
  background-color: #9675ce;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-purple .panel-body {
  background: #9675ce;
  color: #fff
}

.full-panel-purple .panel-footer {
  background: #9675ce;
  color: #fff;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-purple a {
  color: #9675ce
}

.full-panel-purple a:hover {
  color: #6c41b6
}

.full-panel-danger {
  border-color: #fb9678
}

.full-panel-danger .panel-heading {
  border-color: #fb9678;
  color: #fff;
  background-color: #fb9678;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-danger .panel-body {
  background: #fb9678;
  color: #fff
}

.full-panel-danger .panel-footer {
  background: #fb9678;
  color: #fff;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-danger a {
  color: #fb9678
}

.full-panel-danger a:hover {
  color: #f95c2e
}

.full-panel-inverse {
  border-color: #4c5667
}

.full-panel-inverse .panel-heading {
  border-color: #4c5667;
  color: #fff;
  background-color: #4c5667;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-inverse .panel-body {
  background: #4c5667;
  color: #fff
}

.full-panel-inverse .panel-footer {
  background: #4c5667;
  color: #fff;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-inverse a {
  color: #4c5667
}

.full-panel-inverse a:hover {
  color: #2c313b
}

.full-panel-default {
  border-color: rgba(120, 130, 140, .13)
}

.full-panel-default .panel-heading {
  color: #2b2b2b;
  background-color: #fff;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-default .panel-body {
  color: #2b2b2b
}

.full-panel-default .panel-footer {
  background: #fff;
  color: #2b2b2b;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.full-panel-default a {
  color: #2b2b2b
}

.full-panel-default a:hover {
  color: #2c313b
}

.panel-opcl {
  float: right
}

.panel-opcl i {
  margin-left: 8px;
  font-size: 10px;
  cursor: pointer
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, .08);
  transition: all .5s ease-out;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: scale(0);
  transform: scale(0);
  pointer-events: none
}

.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, .4);
  background: radial-gradient(rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, 0) 70%)
}

.waves-effect.waves-classic .waves-ripple {
  background: rgba(0, 0, 0, .2)
}

.waves-effect.waves-classic.waves-light .waves-ripple {
  background: rgba(255, 255, 255, .4)
}

.waves-notransition {
  transition: none!important
}

.waves-button,
.waves-button-input,
.waves-button:hover,
.waves-button:visited {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  z-index: 1
}

.waves-button {
  padding: .85em 1.1em;
  border-radius: .2em
}

.waves-button-input {
  margin: 0;
  padding: .85em 1.1em
}

.waves-input-wrapper {
  border-radius: .2em;
  vertical-align: bottom
}

.waves-input-wrapper.waves-button {
  padding: 0
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%
}



.waves-float:active {
  box-shadow: 0 8px 20px 1px rgba(0, 0, 0, .3)
}

.waves-block {
  display: block
}

.checkbox {
  padding-left: '20px'
}

.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative
}

.checkbox label::before {
  -o-transition: .3s ease-in-out;
  -webkit-transition: .3s ease-in-out;
  background-color: #fff;
  border-radius: 1px;
  border: 1px solid rgba(120, 130, 140, .13);
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: .3s ease-in-out;
  width: 17px;
  outline: 0!important
}

.checkbox label::after {
  color: #2b2b2b;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 0;
  width: 16px
}

.checkbox input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: 0!important
}

.checkbox input[type=checkbox]:disabled+label {
  opacity: .65
}

.checkbox input[type=checkbox]:focus+label::before {
  outline-offset: -2px;
  outline: 0;
  outline: dotted thin
}

.checkbox input[type=checkbox]:checked+label::after {
  content: "\F00C";
  font-family: FontAwesome
}

.checkbox input[type=checkbox]:disabled+label::before {
  background-color: #e4e7ea;
  cursor: not-allowed
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%
}

.checkbox.checkbox-inline {
  margin-top: 0
}

.checkbox.checkbox-single label {
  height: 17px
}

.checkbox-primary input[type=checkbox]:checked+label::before {
  background-color: #ab8ce4;
  border-color: #ab8ce4
}

.checkbox-primary input[type=checkbox]:checked+label::after {
  color: #fff
}

.checkbox-danger input[type=checkbox]:checked+label::before {
  background-color: #fb9678;
  border-color: #fb9678
}

.checkbox-danger input[type=checkbox]:checked+label::after {
  color: #fff
}

.checkbox-info input[type=checkbox]:checked+label::before {
  background-color: #03a9f3;
  border-color: #03a9f3
}

.checkbox-info input[type=checkbox]:checked+label::after {
  color: #fff
}

.checkbox-warning input[type=checkbox]:checked+label::before {
  background-color: #fec107;
  border-color: #fec107
}

.checkbox-warning input[type=checkbox]:checked+label::after {
  color: #fff
}

.checkbox-success input[type=checkbox]:checked+label::before {
  background-color: #00c292;
  border-color: #00c292
}

.checkbox-success input[type=checkbox]:checked+label::after {
  color: #fff
}

.checkbox-purple input[type=checkbox]:checked+label::before {
  background-color: #9675ce;
  border-color: #9675ce
}

.checkbox-purple input[type=checkbox]:checked+label::after {
  color: #fff
}

.checkbox-red input[type=checkbox]:checked+label::before {
  background-color: #fb9678;
  border-color: #fb9678
}

.checkbox-red input[type=checkbox]:checked+label::after {
  color: #fff
}

.checkbox-inverse input[type=checkbox]:checked+label::before {
  background-color: #4c5667;
  border-color: #4c5667
}

.checkbox-inverse input[type=checkbox]:checked+label::after {
  color: #fff
}

.radio {
  padding-left: 20px
}

.radio label {
  display: inline-block;
  padding-left: 5px;
  position: relative
}

.radio label::before {
  -o-transition: border .5s ease-in-out;
  -webkit-transition: border .5s ease-in-out;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid rgba(120, 130, 140, .13);
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: border .5s ease-in-out;
  width: 17px;
  outline: 0!important
}

.radio label::after {
  -moz-transition: -moz-transform .3s cubic-bezier(.8, -.33, .2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform .3s cubic-bezier(.8, -.33, .2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform .3s cubic-bezier(.8, -.33, .2, 1.33);
  background-color: #2b2b2b;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 7px;
  left: 5px;
  margin-left: -20px;
  position: absolute;
  top: 5px;
  transform: scale(0, 0);
  transition: -webkit-transform .3s cubic-bezier(.8, -.33, .2, 1.33);
  transition: transform .3s cubic-bezier(.8, -.33, .2, 1.33);
  transition: transform .3s cubic-bezier(.8, -.33, .2, 1.33), -webkit-transform .3s cubic-bezier(.8, -.33, .2, 1.33);
  width: 7px
}

.radio input[type=radio] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: 0!important
}

.radio input[type=radio]:disabled+label {
  opacity: .65
}

.radio input[type=radio]:focus+label::before {
  outline-offset: -2px;
  outline: -webkit-focus-ring-color auto 5px;
  outline: dotted thin
}

.radio input[type=radio]:checked+label::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1)
}

.radio input[type=radio]:disabled+label::before {
  cursor: not-allowed
}

.radio.radio-inline {
  margin-top: 0
}

.radio.radio-single label {
  height: 17px
}

.radio-primary input[type=radio]+label::after {
  background-color: #ab8ce4
}

.radio-primary input[type=radio]:checked+label::before {
  border-color: #ab8ce4
}

.radio-primary input[type=radio]:checked+label::after {
  background-color: #ab8ce4
}

.radio-danger input[type=radio]+label::after {
  background-color: #fb9678
}

.radio-danger input[type=radio]:checked+label::before {
  border-color: #fb9678
}

.radio-danger input[type=radio]:checked+label::after {
  background-color: #fb9678
}

.radio-info input[type=radio]+label::after {
  background-color: #03a9f3
}

.radio-info input[type=radio]:checked+label::before {
  border-color: #03a9f3
}

.radio-info input[type=radio]:checked+label::after {
  background-color: #03a9f3
}

.radio-warning input[type=radio]+label::after {
  background-color: #fec107
}

.radio-warning input[type=radio]:checked+label::before {
  border-color: #fec107
}

.radio-warning input[type=radio]:checked+label::after {
  background-color: #fec107
}

.radio-success input[type=radio]+label::after {
  background-color: #00c292
}

.radio-success input[type=radio]:checked+label::before {
  border-color: #00c292
}

.radio-success input[type=radio]:checked+label::after {
  background-color: #00c292
}

.radio-purple input[type=radio]+label::after {
  background-color: #9675ce
}

.radio-purple input[type=radio]:checked+label::before {
  border-color: #9675ce
}

.radio-purple input[type=radio]:checked+label::after {
  background-color: #9675ce
}

.radio-red input[type=radio]+label::after {
  background-color: #fb9678
}

.radio-red input[type=radio]:checked+label::before {
  border-color: #fb9678
}

.radio-red input[type=radio]:checked+label::after {
  background-color: #fb9678
}

.fileupload {
  overflow: hidden;
  position: relative
}

.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0
}

.model_img {
  cursor: pointer
}

.myadmin-dd .dd-list .dd-item .dd-handle {
  background: #fff;
  border: 1px solid rgba(120, 130, 140, .13);
  padding: 8px 16px;
  height: auto;
  font-weight: 600;
  border-radius: 0
}

.myadmin-dd .dd-list .dd-item .dd-handle:hover {
  color: #03a9f3
}

.myadmin-dd .dd-list .dd-item button {
  height: auto;
  font-size: 17px;
  margin: 8px auto;
  color: #2b2b2b;
  width: 30px
}

.myadmin-dd-empty .dd-list .dd3-handle {
  border: 1px solid rgba(120, 130, 140, .13);
  border-bottom: 0;
  background: #fff;
  height: 36px;
  width: 36px
}

.myadmin-dd-empty .dd-list .dd3-handle:before {
  color: inherit;
  top: 7px
}

.myadmin-dd-empty .dd-list .dd3-handle:hover {
  color: #03a9f3
}

.myadmin-dd-empty .dd-list .dd3-content {
  height: auto;
  border: 1px solid rgba(120, 130, 140, .13);
  padding: 8px 16px 8px 46px;
  background: #fff;
  font-weight: 600
}

.myadmin-dd-empty .dd-list .dd3-content:hover {
  color: #03a9f3
}

.myadmin-dd-empty .dd-list button {
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-weight: 600
}

.settings_box {
  position: absolute;
  top: 75px;
  right: 0;
  z-index: 100
}

.settings_box a {
  background: #fff;
  padding: 15px;
  display: inline-block;
  vertical-align: top
}

.settings_box a i {
  display: block;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 2s;
  -moz-animation-name: rotate;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotate;
  font-size: 16px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear
}

@-webkit-keyframes rotate {
  from {
      -webkit-transform: rotate(0deg)
  }
  to {
      -webkit-transform: rotate(360deg)
  }
}

@keyframes rotate {
  from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg)
  }
  to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg)
  }
}

.theme_color {
  margin: 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
  width: 0;
  transition: .5s ease-out;
  background: #fff
}

.theme_color li {
  list-style: none;
  width: 30%;
  float: 'left';
  margin: 0 1.5%
}

.theme_color li a {
  padding: 5px;
  height: 50px;
  display: block
}

.theme_color li a.theme-green {
  background: #00c292
}

.theme_color li a.theme-red {
  background: #fb9678
}

.theme_color li a.theme-dark {
  background: #4c5667
}

.theme_block {
  width: 200px;
  padding: 30px
}

ul.common li {
  display: inline-block;
  line-height: 40px;
  list-style: none none;
  width: 48%
}

ul.common li a {
  color: #686868
}

ul.common li a:hover {
  color: #03a9f3
}

.card-primary {
  background-color: #ab8ce4;
  border-color: #ab8ce4
}

.card-success {
  background-color: #00c292;
  border-color: #00c292
}

.card-info {
  background-color: #03a9f3;
  border-color: #03a9f3
}

.card-warning {
  background-color: #fec107;
  border-color: #fec107
}

.card-danger {
  background-color: #fb9678;
  border-color: #fb9678
}

.card-secondary {
  background-color: #4c5667;
  border-color: #4c5667
}

.card-red {
  background-color: #fb3a3a;
  border-color: #fb3a3a
}

.card-blue {
  background-color: #02bec9;
  border-color: #02bec9
}

.card-purple {
  background-color: #9675ce;
  border-color: #9675ce
}

.card-megna {
  background-color: #01c0c8;
  border-color: #01c0c8
}

.card-outline-primary {
  border-color: #ab8ce4
}

.card-outline-success {
  border-color: #00c292
}

.card-outline-info {
  border-color: #03a9f3
}

.card-outline-warning {
  border-color: #fec107
}

.card-outline-danger {
  border-color: #fb9678
}

.card-outline-secondary {
  border-color: #4c5667
}

.card-outline-red {
  border-color: #fb3a3a
}

.card-outline-blue {
  border-color: #02bec9
}

.card-outline-purple {
  border-color: #9675ce
}

.card-outline-megna {
  border-color: #01c0c8
}

.row-in i {
  font-size: 24px
}

.mailbox {
  width: 280px;
  overflow: auto;
  padding-bottom: 0
}

.message-center a {
  border-bottom: 1px solid rgba(120, 130, 140, .13);
  display: block;
  padding: 9px 15px
}

.message-center a:hover {
  background: #f7fafc
}

.message-center .user-img {
  width: 40px;
  float: 'left';
  position: relative;
  margin: 0 10px 15px 0
}

.message-center .user-img img {
  width: 100%
}

.message-center .user-img .profile-status {
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 30px;
  position: absolute;
  top: 1px;
  width: 10px
}

.message-center .user-img .online {
  background: #00c292
}

.message-center .user-img .busy {
  background: #fb9678
}

.message-center .user-img .away,
.message-center .user-img .offline {
  background: #fec107
}

.message-center .mail-contnet h5 {
  margin: 0;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.message-center .mail-contnet .mail-desc {
  font-size: 12px;
  display: block;
  margin: 5px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #2b2b2b
}

.message-center .mail-contnet .time {
  display: block;
  font-size: 10px;
  color: #2b2b2b
}

.mail-contnet a.action {
  margin-left: 10px;
  font-size: 12px;
  visibility: hidden
}

.mail-contnet:hover a.action {
  visibility: visible
}

.inbox-center .unread td {
  font-weight: 600
}

.inbox-center a {
  color: #686868;
  padding: 2px 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block
}

.comment-center {
  margin: 0 0
}

.comment-center .comment-body {
  border-bottom: 1px solid rgba(120, 130, 140, .13);
  display: table;
  padding: 5px 5px
}

.comment-center .comment-body:hover {
  background: #f7fafc
}

.comment-center .user-img {
  width: 40px;
  display: table-cell;
  position: relative;
  margin: 0 10px 0 0
}

.comment-center .user-img img {
  width: 100%
}

.comment-center .mail-contnet {
  display: table-cell;
  padding-left: 15px;
  vertical-align: top
}

.comment-center .mail-contnet h5 {
  margin: 0;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.comment-center .mail-contnet .mail-desc {
  font-size: 14px;
  margin: 8px 0;
  line-height: 25px;
  color: #848a96;
  height: 50px;
  overflow: hidden
}

.comment-center .mail-contnet .time {
  display: block;
  font-size: 10px;
  color: #2b2b2b
}

.sales-report {
  background: #f7fafc;
  margin: 12px -25px;
  padding: 15px
}

.dropdown-alerts,
.dropdown-tasks {
  padding: 0
}

.dropdown-alerts li a,
.dropdown-tasks li a,
.mailbox li>a {
  padding: 15px 20px
}

.dropdown-alerts li.divider,
.dropdown-tasks li.divider {
  margin: 0
}

.row-in-br {
  border-right: 1px solid rgba(120, 130, 140, .13)
}

.col-in {
  padding: 20px
}

.col-in h3 {
  font-size: 48px;
  font-weight: 100
}

.basic-list {
  padding: 0
}

.basic-list li {
  display: block;
  padding: 15px 0;
  border-bottom: 1px solid rgba(120, 130, 140, .13);
  line-height: 27px
}

.basic-list li:last-child {
  border-bottom: 0
}

.steamline {
  position: relative;
  border-left: 1px solid rgba(120, 130, 140, .13);
  margin-left: 20px
}

.steamline .sl-left {
  float: 'left';
  margin-left: -20px;
  z-index: 1;
  margin-right: 15px
}

.steamline .sl-left img {
  max-width: 40px
}

.steamline .sl-right {
  padding-left: 35px
}

.steamline .sl-item {
  margin-top: 8px;
  margin-bottom: 30px
}

.sl-date {
  font-size: 10px;
  color: #98a6ad
}

.time-item {
  border-color: rgba(120, 130, 140, .13);
  padding-bottom: 1px;
  position: relative
}

.time-item:before {
  content: " ";
  display: table
}

.time-item:after {
  background-color: #fff;
  border-color: rgba(120, 130, 140, .13);
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px
}

.time-item-item:after {
  content: " ";
  display: table
}

.item-info {
  margin-bottom: 15px;
  margin-left: 15px
}

.item-info p {
  margin-bottom: 10px!important
}

.user-bg {
  margin: -25px;
  height: 230px;
  overflow: hidden;
  position: relative
}

.user-bg .overlay-box {
  background: #B7D5E6;
  opacity: .8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  text-align: center
}

.user-bg .overlay-box .user-content {
  padding: 15px;
  margin-top: 30px
}

.user-btm-box {
  padding: 40px 0 10px;
  clear: both;
  overflow: hidden
}

.vertical .carousel-inner {
  height: 100%;
  position: relative
}

.carousel.vertical .item {
  transition: .6s ease-in-out top
}

.carousel.vertical .active {
  top: 0
}

.carousel.vertical .next {
  top: 400px
}

.carousel.vertical .prev {
  top: -400px
}

.carousel.vertical .next.left,
.carousel.vertical .prev.right {
  top: 0
}

.carousel.vertical .active.left {
  top: -400px
}

.carousel.vertical .active.right {
  top: 400px
}

.carousel.vertical .item {
  left: 0
}

.twi-user img {
  margin-right: 20px;
  width: 50px
}

.twi-user {
  margin: 18px 0
}

.carousel-inner h3 {
  height: 112px;
  overflow: hidden
}

.chart-box {
  margin: 25px -15px -17px -17px
}

.list-task .task-done span {
  text-decoration: line-through
}

.chat-list {
  list-style: none;
  max-height: 332px;
  padding: 0 20px
}

.chat-list li {
  margin-bottom: 24px;
  overflow: auto
}



.chat-list .chat-image img {
  border-radius: 100%;
  width: 100%
}

.chat-list .chat-text {
  background: #f7fafc;
  border-radius: 0;
  display: inline-block;
  padding: 15px;
  position: relative
}

.chat-list .chat-text h4 {
  color: #1a2942;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  line-height: 15px;
  position: relative
}

.chat-list .chat-text p {
  margin: 0;
  padding-top: 3px
}

.chat-list .chat-text b {
  font-size: 10px;
  opacity: .8
}



.chat-list .odd .chat-image {
  float: right!important
}

.chat-list .odd .chat-body {
  float: right!important;
  margin-right: 12px;
  text-align: right;
  color: #fff
}

.chat-list .odd .chat-text {
  background: #ff6849
}

.chat-list .odd .chat-text h4 {
  color: #fff
}

.chat-send {
  padding-left: 0;
  padding-right: 30px
}

.chat-send button {
  width: 100%
}

.weather-box .weather-top {
  overflow: hidden;
  padding: 10px 25px;
  margin: 0 -25px;
  background: #f7fafc
}

.weather-box .weather-top h2 {
  line-height: 24px
}

.weather-box .weather-top h2 small {
  font-size: 13px
}

.weather-box .weather-top .today_crnt {
  font-size: 45px;
  font-weight: 100
}

.weather-box .weather-top .today_crnt canvas {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle
}

.weather-box .weather-info {
  padding: 10px 0
}

.weather-box .weather-time {
  overflow: hidden;
  text-align: center;
  padding-top: 15px
}

.weather-box .weather-time li span {
  display: block
}

.weather-box .weather-time li canvas {
  font-size: 20px;
  margin: 10px 0
}

.demo-container {
  width: 100%;
  height: 350px
}

.demo-placeholder {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em
}

.myadmin-alert {
  border-radius: 0;
  color: #fff;
  padding: 12px 30px 12px 12px;
  position: relative;
  text-align: left
}

.myadmin-alert a {
  color: inherit;
  font-weight: 600;
  text-decoration: underline
}

.myadmin-alert h4 {
  color: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0
}

.myadmin-alert .img {
  border-radius: 3px;
  height: 40px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 40px
}

.myadmin-alert-img {
  min-height: 64px;
  padding-left: 65px
}

.myadmin-alert-icon {
  padding-left: 20px
}

.myadmin-alert-icon i {
  padding-right: 10px
}

.myadmin-alert .closed {
  color: rgba(255, 255, 255, .5);
  font-size: 20px;
  font-weight: 700;
  padding: 4px;
  position: absolute;
  right: 3px;
  text-decoration: none;
  top: 0
}

.myadmin-alert .closed:hover {
  color: #fff
}

.myadmin-alert-click {
  cursor: pointer;
  padding-right: 12px
}

.myadmin-alert .primary {
  background: rgba(0, 0, 0, .4);
  border: none;
  border-radius: 3px;
  color: inherit;
  outline: 0;
  padding: 4px 10px
}

.myadmin-alert .cancel {
  background: rgba(255, 255, 255, .4);
  border: none;
  border-radius: 3px;
  color: rgba(0, 0, 0, .8);
  outline: 0;
  padding: 4px 10px
}

.myadmin-alert .cancel:hover,
.myadmin-alert .primary:hover {
  opacity: .9
}

.myadmin-alert-bottom,
.myadmin-alert-bottom-left,
.myadmin-alert-bottom-right,
.myadmin-alert-fullscreen,
.myadmin-alert-top,
.myadmin-alert-top-left,
.myadmin-alert-top-right {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .1);
  display: none;
  position: fixed;
  z-index: 1000
}

.myadmin-alert-top {
  left: 0;
  right: 0;
  top: 0
}

.myadmin-alert-bottom {
  bottom: 0;
  left: 0;
  right: 0
}

.myadmin-alert-top-left {
  left: 20px;
  top: 80px
}

.myadmin-alert-top-right {
  right: 20px;
  top: 80px
}

.myadmin-alert-bottom-left {
  bottom: 20px;
  left: 20px
}

.myadmin-alert-bottom-right {
  bottom: 20px;
  right: 20px
}

.myadmin-alert-fullsize {
  left: 50%;
  margin: -20px;
  top: 50%
}

.alert-custom {
  background: #ff6849;
  color: #fff;
  border-color: #ff6849
}

.alert-inverse {
  background: #4c5667;
  color: #fff;
  border-color: #4c5667
}

.alert-success {
  background: #00c292;
  color: #fff;
  border-color: #00c292
}

.alert-dark {
  background: #686868;
  color: #fff;
  border-color: #686868
}

.alert-warning {
  background: #fec107;
  color: #fff;
  border-color: #fec107
}

.alert-danger {
  background: #fb9678;
  color: #fff;
  border-color: #fb9678
}

.alert-primary {
  background: #9675ce;
  color: #fff;
  border-color: #9675ce
}

.alert-info {
  background: #03a9f3;
  color: #fff;
  border-color: #03a9f3
}

.alert-info .closed,
.alert-info a.closed:hover {
  color: inherit
}

.tab-content {
  margin-top: 30px
}

.customtab {
  border-bottom: 2px solid #f7fafc
}

.customtab li.active a,
.customtab li.active a:focus,
.customtab li.active a:hover {
  background: #fff;
  border: 0;
  border-bottom: 2px solid #01c0c8;
  margin-bottom: -1px;
  color: #ff6849
}

.customtab li a,
.customtab li a:focus,
.customtab li a:hover {
  border: 0
}

.customtab3 {
  border-bottom: 2px solid #f7fafc
}

.customtab3 li.active a,
.customtab3 li.active a:focus,
.customtab3 li.active a:hover {
  background: #fff;
  border: 0;
  border-bottom: 2px solid #01c0c8;
  margin-bottom: -1px;
  color: #ff6849
}

.customtab3 li a,
.customtab3 li a:focus,
.customtab3 li a:hover {
  border: 0
}

customtab2 {
  border-bottom: 1px solid #f7fafc;
  border-top: 1px solid #f7fafc;
  padding: 10px 0
}

.customtab2 li.active a,
.customtab2 li.active a:focus,
.customtab2 li.active a:hover {
  background: #ff6849;
  border: 1px solid #ff6849;
  color: #fff
}

.customtab2 li a,
.customtab2 li a:focus,
.customtab2 li a:hover {
  border: 0
}

.vtabs {
  display: table
}

.vtabs .tabs-vertical {
  width: 150px;
  border-right: 1px solid rgba(120, 130, 140, .13);
  display: table-cell;
  vertical-align: top
}

.vtabs .tabs-vertical li a {
  color: #2b2b2b;
  margin-bottom: 10px
}

.vtabs .tab-content {
  display: table-cell;
  padding: 20px;
  vertical-align: top
}

.tabs-vertical li.active a,
.tabs-vertical li.active a:focus,
.tabs-vertical li.active a:hover {
  background: #ff6849;
  border: 0;
  border-right: 2px solid #ff6849;
  margin-right: -1px;
  color: #fff
}

.customvtab .tabs-vertical li.active a,
.customvtab .tabs-vertical li.active a:focus,
.customvtab .tabs-vertical li.active a:hover {
  background: #fff;
  border: 0;
  border-right: 2px solid #ff6849;
  margin-right: -1px;
  color: #2b2b2b
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  background: #ff6849;
  color: #fff
}

.nav-pills>li>a {
  color: #2b2b2b;
  border-radius: 0
}

.panel-group .panel .panel-heading .accordion-toggle.collapsed:before,
.panel-group .panel .panel-heading a[data-toggle=collapse].collapsed:before {
  content: '\E64B'
}

.panel-group .panel .panel-heading a[data-toggle=collapse] {
  display: block
}

.panel-group .panel .panel-heading a[data-toggle=collapse]:before {
  content: '\E648';
  display: block;
  float: right;
  font-family: themify;
  font-size: 14px;
  text-align: right;
  width: 25px
}

.panel-group .panel .panel-heading .accordion-toggle {
  display: block
}

.panel-group .panel .panel-heading .accordion-toggle:before {
  content: '\E648';
  display: block;
  float: right;
  font-family: themify;
  font-size: 14px;
  text-align: right;
  width: 25px
}

.panel-group .panel .panel-heading+.panel-collapse .panel-body {
  border-top: none
}

.panel-group .panel-heading {
  padding: 12px 20px
}

.progress {
  -webkit-box-shadow: none!important;
  background-color: rgba(120, 130, 140, .13);
  box-shadow: none!important;
  height: 4px;
  border-radius: 0;
  margin-bottom: 18px;
  overflow: hidden
}

.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px
}

.progress.progress-sm {
  height: 8px!important
}

.progress.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px
}

.progress.progress-md {
  height: 15px!important
}

.progress.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px
}

.progress.progress-lg {
  height: 20px!important
}

.progress.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px
}

.progress-bar-primary {
  background-color: #ab8ce4
}

.progress-bar-success {
  background-color: #00c292
}

.progress-bar-info {
  background-color: #03a9f3
}

.progress-bar-megna {
  background-color: #01c0c8
}

.progress-bar-warning {
  background-color: #fec107
}

.progress-bar-danger {
  background-color: #fb9678
}

.progress-bar-inverse {
  background-color: #4c5667
}

.progress-bar-purple {
  background-color: #9675ce
}

.progress-bar-custom {
  background-color: #03a9f3
}

.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: myanimation;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: myanimation;
  transition: 5s all
}

@-webkit-keyframes myanimation {
  from {
      width: 0
  }
}

@keyframes myanimation {
  from {
      width: 0
  }
}

.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 4px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px
}

.progress-vertical .progress-bar {
  width: 100%
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 4px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px
}

.progress-vertical-bottom .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0
}

.progress-vertical-bottom.progress-sm,
.progress-vertical.progress-sm {
  width: 8px!important
}

.progress-vertical-bottom.progress-sm .progress-bar,
.progress-vertical.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px
}

.progress-vertical-bottom.progress-md,
.progress-vertical.progress-md {
  width: 15px!important
}

.progress-vertical-bottom.progress-md .progress-bar,
.progress-vertical.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px
}

.progress-vertical-bottom.progress-lg,
.progress-vertical.progress-lg {
  width: 20px!important
}

.progress-vertical-bottom.progress-lg .progress-bar,
.progress-vertical.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px
}

.timeline {
  position: relative;
  padding: 20px 0;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto
}

.timeline:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  margin-left: -1.5px;
  background-color: #eee
}

.timeline>li {
  position: relative;
  margin-bottom: 20px
}

.timeline>li:after,
.timeline>li:before {
  content: " ";
  display: table
}

.timeline>li:after {
  clear: both
}

.timeline>li>.timeline-panel {
  float: 'left';
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid rgba(120, 130, 140, .13);
  border-radius: 0;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .05)
}

.timeline>li>.timeline-panel:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 26px;
  right: -8px;
  border-top: 8px solid transparent;
  border-right: 0 solid rgba(120, 130, 140, .13);
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(120, 130, 140, .13)
}

.timeline>li>.timeline-panel:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #fff;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff
}

.timeline>li>.timeline-badge {
  z-index: 100;
  position: absolute;
  top: 16px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50%;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: #fff;
  overflow: hidden;
  background-color: #4c5667
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0
}

.timeline-badge.primary {
  background-color: #ab8ce4!important
}

.timeline-badge.success {
  background-color: #00c292!important
}

.timeline-badge.warning {
  background-color: #fec107!important
}

.timeline-badge.danger {
  background-color: #fb9678!important
}

.timeline-badge.info {
  background-color: #03a9f3!important
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400
}

.timeline-body>p,
.timeline-body>ul {
  margin-bottom: 0
}

.timeline-body>p+p {
  margin-top: 5px
}

.chart {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0
}

.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px
}

.pie-chart>span {
  left: 0;
  margin-top: -2px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%)
}

.chart>span>img {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  width: 60%;
  height: 60%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0 auto
}

.percent {
  display: inline-block;
  line-height: 100px;
  z-index: 2;
  font-weight: 600;
  font-size: 18px;
  color: #2b2b2b
}

.percent:after {
  content: '%';
  margin-left: .1em;
  font-size: .8em
}

.table {
  margin-bottom: 10px
}

.table-hover>tbody>tr:hover,
.table-striped>tbody>tr:nth-of-type(odd),
.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
  background-color: #f7fafc!important
}

.table-bordered,
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  border-top: 1px solid #e4e7ea
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 15px 8px
}

.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
  border: 1px solid #e4e7ea
}

.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 1px solid #e4e7ea
}

tbody {
  color: #797979
}

th {
  color: #666;
  font-weight: 500
}

.table-bordered {
  border: 1px solid #e4e7ea
}

table.focus-on tbody tr.focused td,
table.focus-on tbody tr.focused th {
  background-color: #ff6849;
  color: #fff
}

.table-rep-plugin .table-responsive {
  border: none!important
}

.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: 400
}

.jsgrid .jsgrid-table {
  margin-bottom: 0
}

.jsgrid-selected-row>td {
  background: #f7fafc;
  border-color: #f7fafc
}

.jsgrid-header-row>th {
  background: #fff
}

.has-success .form-control {
  border-color: #00c292;
  box-shadow: none!important
}

.has-warning .form-control {
  border-color: #fec107;
  box-shadow: none!important
}

.has-error .form-control {
  border-color: #fb9678;
  box-shadow: none!important
}

.input-group-addon {
  border-radius: 2px;
  border: 1px solid rgba(120, 130, 140, .13)
}

.input-daterange input:first-child,
.input-daterange input:last-child {
  border-radius: 0
}

.form-material .form-group {
  overflow: hidden
}

.form-material .form-control {
  background-color: rgba(0, 0, 0, 0);
  background-position: center bottom, center calc(99%);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: linear-gradient(#9ce6dd, #9ce6dd), linear-gradient(rgba(120, 130, 140, .13), rgba(120, 130, 140, .13));
  border: 0;
  border-radius: 0;
  box-shadow: none;
  float: none
}

.form-material .form-control.focus,
.form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0;
  transition-duration: .3s
}

.form-bordered .form-group {
  border-bottom: 1px solid rgba(120, 130, 140, .13);
  padding-bottom: 20px
}

.select2-container .select2-choice {
  background-image: none!important;
  border: none!important;
  height: auto!important;
  padding: 0!important;
  line-height: 22px!important;
  background-color: transparent!important;
  box-shadow: none!important
}

.select2-container .select2-choice .select2-arrow {
  background-image: none!important;
  background: 0 0;
  border: none;
  width: 14px;
  top: -2px
}

.select2-container .select2-container-multi.form-control {
  height: auto
}

.select2-results .select2-highlighted {
  color: #fff;
  background-color: #03a9f3
}

.select2-drop-active {
  border: 1px solid #e3e3e3!important;
  padding-top: 5px
}

.select2-search input {
  border: 1px solid rgba(120, 130, 140, .13)
}

.select2-container-multi {
  width: 100%
}

.select2-container-multi .select2-choices {
  border: '1px solid #border!important';
  box-shadow: 'none!important';
  background-image: 'none!important';
  border-radius: '0!important';
  min-height: '38px'
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 4px 7px 4px 18px;
  margin: 5px 0 3px 5px;
  color: #555;
  background: #f5f5f5;
  border-color: rgba(120, 130, 140, .13);
  box-shadow: none
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 7px 7px 7px 10px;
  font-family: inherit
}

.icon-list-demo div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  color: #686868
}

.icon-list-demo div:hover {
  color: #2b2b2b
}

.icon-list-demo div p {
  margin: 10px 0;
  padding: 5px 0
}

.icon-list-demo i {
  -webkit-transition: all .2s;
  -webkit-transition: font-size .2s;
  display: inline-block;
  font-size: 18px;
  margin: 0 15px 0 10px;
  text-align: 'left';
  vertical-align: middle;
  width: auto;
  transition: all .3s ease 0s
}

.icon-list-demo .col-md-4 {
  border-radius: 0
}

.icon-list-demo .col-md-4:hover {
  background-color: #f7fafc
}

.icon-list-demo .col-md-4:hover i {
  font-size: 2em
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #e4e7ea;
  border-radius: 3px
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  background: #ab8ce4;
  border-radius: 4px;
  padding: 10px 20px
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute
}

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #ab8ce4
}

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #ab8ce4
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 10px;
  height: 10px;
  line-height: 10px
}

.jvectormap-zoomout {
  top: 40px
}

.error-box .footer {
  width: 100%;
  left: 0;
  right: 0
}

.error-body {
  padding-top: 5%
}

.error-body h1 {
  font-size: 210px;
  font-weight: 900;
  line-height: 210px
}

.login-register {
  background: url(/images/login-register.jpg) center center/cover no-repeat!important;
  height: 100%;
  position: fixed
}

.login-box {
  background: #fff;
  width: 400px;
  margin: 10% auto 0
}

.login-box .footer {
  width: 100%;
  left: 0;
  right: 0
}

.login-box .social {
  display: block;
  margin-bottom: 30px
}

#recoverform {
  display: none
}

.pricing-box {
  position: relative;
  text-align: center;
  margin-top: 30px
}

.featured-plan {
  margin-top: 0
}

.featured-plan .pricing-body {
  padding: 60px 0;
  background: #f7fafc;
  border: 1px solid #ddd
}

.featured-plan .price-table-content .price-row {
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.pricing-body {
  border-radius: 0;
  border-top: 1px solid rgba(120, 130, 140, .13);
  border-bottom: 5px solid rgba(120, 130, 140, .13);
  vertical-align: middle;
  padding: 30px 0;
  position: relative
}

.pricing-body h2 {
  position: relative;
  font-size: 56px;
  margin: 20px 0 10px;
  font-weight: 700
}

.pricing-body h2 span {
  position: absolute;
  font-size: 15px;
  top: -10px;
  margin-left: -10px
}

.price-table-content .price-row {
  padding: 20px 0;
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.pricing-plan {
  padding: 0 15px
}

.pricing-plan .no-padding {
  padding: 0
}

.price-lable {
  position: absolute;
  top: -10px;
  padding: 5px 10px;
  margin: 0 auto;
  display: inline-block;
  width: 100px;
  left: 0;
  right: 0
}

.mails a {
  color: #2b2b2b
}

.mails td {
  vertical-align: middle!important;
  position: relative
}

.mails td:last-of-type {
  width: 100px;
  padding-right: 20px
}

.mails tr:hover .text-white {
  display: none
}

.mails .mail-select {
  padding: 12px 20px;
  min-width: 134px
}

.mails .checkbox {
  margin-bottom: 0;
  margin-top: 0;
  vertical-align: middle;
  display: inline-block;
  height: 17px
}

.mails .checkbox label {
  min-height: 16px
}

.mail-list .list-group-item {
  background-color: transparent;
  border: 0;
  border-left: 3px solid #fff;
  border-radius: 0
}

.mail-list .list-group-item:hover {
  background: #f7fafc;
  border-left: 3px solid #f7fafc
}

.mail-list .list-group-item:focus {
  border-left: 3px solid #f7fafc
}

.mail-list .list-group-item.active:focus {
  background: #f7fafc;
  border-left: 3px solid #fb9678
}

.mail-list .list-group-item.active {
  border-left: 3px solid #fb9678;
  border-radius: 0;
  color: #2b2b2b!important
}

.mail_listing {
  min-height: 500px
}

.inbox_listing .inbox-item:hover {
  background: #f7fafc
}

.inbox_listing .inbox-item {
  padding-left: 20px
}

.inbox-widget.inbox_listing .inbox-item .inbox-item-text {
  height: 19px;
  overflow: hidden
}

.message-center .unread .mail-contnet .mail-desc,
.message-center .unread .mail-contnet h5 {
  font-weight: 600;
  color: #2b2b2b!important
}

.calendar {
  float: 'left';
  margin-bottom: 0
}

.fc-view {
  margin-top: 30px
}

.none-border .modal-footer {
  border-top: none
}

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase
}

.fc-day {
  background: #fff
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0
}

.fc-widget-header {
  border: 0!important
}

.fc-widget-content {
  border-color: rgba(120, 130, 140, .13)!important
}

.fc th.fc-widget-header {
  background: #007765;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 7px 0;
  text-transform: uppercase
}

.fc-button {
  background: #fff;
  border: 1px solid rgba(120, 130, 140, .13);
  color: #555;
  text-transform: capitalize
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px
}

.fc-state-hover {
  background: #F5F5F5
}

.fc-unthemed .fc-today {
  border: 1px solid #fb9678;
  background: #b3d6d1!important
}

.fc-cell-overlay,
.fc-state-highlight {
  background: #f0f0f0
}

.fc-event {
  border-radius: 0;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 1px -1px 0;
  padding: 5px;
  text-align: center;
  background: #03a9f3
}

.calendar-event {
  /* cursor: move; */
  margin: 10px 5px 0 0;
  padding: 6px 10px;
  display: inline-block;
  color: #fff;
  /* min-width: 140px; */
  text-align: center;
  background: #03a9f3
}

.calendar-event a {
  float: right;
  opacity: .6;
  font-size: 10px;
  margin: 4px 0 0 10px;
  color: #fff
}

.fc-basic-view td.fc-day-number,
.fc-basic-view td.fc-week-number span {
  padding-right: 5px
}

.weather h1 {
  color: #fff;
  font-size: 50px;
  font-weight: 100
}

.weather i {
  color: #fff;
  font-size: 40px
}

.weather .w-title-sub {
  color: rgba(255, 255, 255, .6)
}

.navbar-top-links>li.right-side-toggle a:focus {
  background: #4f5467;
  border-radius: 10px
}

.right-sidebar {
  position: fixed;
  right: -240px;
  width: 240px;
  display: none;
  z-index: 1000;
  background: #fff;
  top: 0;
  height: 100%;
  box-shadow: 5px 1px 40px rgba(0, 0, 0, .1);
  transition: all .3s ease
}

.right-sidebar .rpanel-title {
  display: block;
  padding: 21px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  background: #ff6849
}

.right-sidebar .rpanel-title span {
  float: right;
  cursor: pointer;
  font-size: 11px
}

.right-sidebar .rpanel-title span:hover {
  color: #2b2b2b
}

.right-sidebar .r-panel-body {
  padding: 20px
}

.right-sidebar .r-panel-body ul {
  margin: 0;
  padding: 0
}

.right-sidebar .r-panel-body ul li {
  list-style: none;
  padding: 5px 0
}

.shw-rside {
  right: 0;
  width: 240px;
  display: block
}

.chatonline img {
  margin-right: 10px;
  float: 'left';
  width: 30px
}

.chatonline li a {
  padding: 15px 0;
  float: 'left';
  width: 100%
}

.chatonline li a span {
  color: #686868
}

.chatonline li a span small {
  display: block;
  font-size: 10px
}

ul#themecolors {
  display: block
}

ul#themecolors li {
  display: inline-block
}

ul#themecolors li:first-child {
  display: block
}

#themecolors li a {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 5px;
  color: transparent;
  position: relative
}

#themecolors li a.working:before {
  content: "\F00C";
  font-family: FontAwesome;
  font-size: 18px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  text-align: center
}

.default-theme {
  background: #fb9678
}

.green-theme {
  background: #00c292
}

.yellow-theme {
  background: #a0aec4
}

.blue-theme {
  background: #03a9f3
}

.purple-theme {
  background: #9675ce
}

.megna-theme {
  background: #01c0c8
}

.default-dark-theme {
  background: #4f5467;
  background: linear-gradient(to right, #4f5467 0, #4f5467 23%, #fb9678 23%, #fb9678 99%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#4f5467', endColorstr='#fb9678', GradientType=1)
}

.green-dark-theme {
  background: #4f5467;
  background: linear-gradient(to right, #4f5467 0, #4f5467 23%, #00c292 23%, #00c292 99%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#4f5467', endColorstr='#00c292', GradientType=1)
}

.yellow-dark-theme {
  background: #4f5467;
  background: linear-gradient(to right, #4f5467 0, #4f5467 23%, #a0aec4 23%, #a0aec4 99%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#4f5467', endColorstr='#a0aec4', GradientType=1)
}

.blue-dark-theme {
  background: #4f5467;
  background: linear-gradient(to right, #4f5467 0, #4f5467 23%, #03a9f3 23%, #03a9f3 99%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#4f5467', endColorstr='@info', GradientType=1)
}

.purple-dark-theme {
  background: #4f5467;
  background: linear-gradient(to right, #4f5467 0, #4f5467 23%, #9675ce 23%, #9675ce 99%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#4f5467', endColorstr='@purple', GradientType=1)
}

.megna-dark-theme {
  background: #4f5467;
  background: linear-gradient(to right, #4f5467 0, #4f5467 23%, #01c0c8 23%, #01c0c8 99%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#4f5467', endColorstr='@megna', GradientType=1)
}

.visited li a {
  color: #686868
}

.visited li.active a {
  color: #ff6849
}

.stats-row {
  margin-bottom: 20px
}

.stat-item {
  display: inline-block;
  padding-right: 15px
}

.stat-item+.stat-item {
  padding-left: 15px;
  border-left: 1px solid #eee
}

.country-state {
  list-style: none;
  margin: 0;
  padding: 0 0 0 10px
}

.country-state h2 {
  margin: 0
}

.country-state .progress {
  margin-top: 8px
}

.two-part li {
  width: 48.8%
}

.two-part li i {
  font-size: 50px
}

.two-part li span {
  font-size: 50px;
  font-weight: 100;
  font-family: Poppins, sans-serif
}

.news-slide {
  position: relative
}

.news-slide .overlaybg {
  height: 360px;
  overflow: hidden
}

.news-slide .overlaybg img {
  width: 100%;
  height: 100%
}

.news-slide .news-content {
  position: absolute;
  height: 360px;
  background: rgba(0, 0, 0, .5);
  z-index: 10;
  width: 100%;
  top: 0;
  padding: 30px
}

.news-slide .news-content h2 {
  height: 240px;
  overflow: hidden;
  color: #fff
}

.news-slide .news-content a {
  color: #fff;
  opacity: .6;
  text-transform: uppercase
}

.news-slide .news-content a:hover {
  opacity: 1
}

.nav-pills-rounded li {
  display: inline-block;
  float: none
}

.nav-pills-rounded li a {
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  color: #686868;
  padding: 10px 25px
}

.nav-pills-rounded li.active a,
.nav-pills-rounded li.active a:focus,
.nav-pills-rounded li.active a:hover {
  background: #ff6849;
  color: #fff
}

.analytics-info .list-inline {
  margin-bottom: 0
}

.analytics-info .list-inline li {
  vertical-align: middle
}

.analytics-info .list-inline li span {
  font-size: 24px
}

.analytics-info .list-inline li i {
  font-size: 20px
}

.feeds {
  margin: 0;
  padding: 0
}

.feeds li {
  list-style: none;
  padding: 10px;
  display: block
}

.feeds li:hover {
  background: #f7fafc
}

.feeds li>div {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%
}

.feeds li>div i {
  line-height: 40px
}

.feeds li span {
  float: right;
  width: auto;
  font-size: 12px
}

.jq-icon-info {
  background-color: #03a9f3;
  color: #fff
}

.jq-icon-success {
  background-color: #00c292;
  color: #fff
}

.jq-icon-error {
  background-color: #fb9678;
  color: #fff
}

.jq-icon-warning {
  background-color: #fec107;
  color: #fff
}

.dropzone {
  border-style: dashed;
  border-width: 1px
}

.weather h1 sup {
  font-size: 20px;
  top: -1.2em
}

.fcbtn {
  position: relative;
  transition: all .3s;
  padding: 8px 20px
}

.fcbtn:after {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all .3s
}

.btn-1b:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0
}

.btn-1b:active,
.btn-1b:hover {
  color: #fff
}

.btn-1b:active:after,
.btn-1b:hover:after {
  height: 100%
}

.btn-1b.btn-info:after,
.btn-1c.btn-info:after,
.btn-1d.btn-info:after,
.btn-1e.btn-info:after,
.btn-1f.btn-info:after {
  background: #03a9f3
}

.btn-1b.btn-warning:after,
.btn-1c.btn-warning:after,
.btn-1d.btn-warning:after,
.btn-1e.btn-warning:after,
.btn-1f.btn-warning:after {
  background: #fec107
}

.btn-1b.btn-danger:after,
.btn-1c.btn-danger:after,
.btn-1d.btn-danger:after,
.btn-1e.btn-danger:after,
.btn-1f.btn-danger:after {
  background: #fb9678
}

.btn-1b.btn-primary:after,
.btn-1c.btn-primary:after,
.btn-1d.btn-primary:after,
.btn-1e.btn-primary:after,
.btn-1f.btn-primary:after {
  background: #9675ce
}

.btn-1b.btn-success:after,
.btn-1c.btn-success:after,
.btn-1d.btn-success:after,
.btn-1e.btn-success:after,
.btn-1f.btn-success:after {
  background: #00c292
}

.btn-1b.btn-inverse:after,
.btn-1c.btn-inverse:after,
.btn-1d.btn-inverse:after,
.btn-1e.btn-inverse:after,
.btn-1f.btn-inverse:after {
  background: #4c5667
}

.btn-1c:after {
  width: 0;
  height: 100%;
  top: 0;
  left: 0
}

.btn-1c:active,
.btn-1c:hover {
  color: #000
}

.btn-1c:active:after,
.btn-1c:hover:after {
  width: 100%
}

.btn-1d {
  overflow: hidden
}

.btn-1d:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.btn-1d:hover:after {
  width: 100%;
  opacity: 1
}

.btn-1e {
  overflow: hidden
}

.btn-1e:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background: #fff;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg)
}

.btn-1e:hover:after {
  height: 260%;
  opacity: 1
}

.btn-1e:active:after {
  height: 400%;
  opacity: 1
}

.btn-1f {
  overflow: hidden
}

.btn-1f:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background: #fff;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.btn-1f:hover:after {
  height: 100%;
  opacity: 1
}

.btn-1f:active:after {
  height: 130%;
  opacity: 1
}

.sweet-alert {
  padding: 25px
}

.sweet-alert h2 {
  margin-top: 0
}

.sweet-alert p {
  line-height: 30px
}

ul.list-icons {
  margin: 0;
  padding: 0
}

ul.list-icons li {
  list-style: none;
  line-height: 40px
}

ul.list-icons li i {
  font-size: 12px;
  margin-right: 5px
}

.demo-popover .popover,
.demo-tooltip .tooltip {
  position: relative;
  margin-right: 25px;
  opacity: 1;
  display: inline-block
}

.tooltip-inner {
  border-radius: 3px;
  padding: 5px 10px
}

.tooltip.in {
  opacity: 1
}

.tooltip-primary+.tooltip .tooltip-inner,
.tooltip-primary.tooltip .tooltip-inner {
  color: #fff;
  background-color: #ab8ce4
}

.tooltip-primary+.tooltip.top .tooltip-arrow,
.tooltip-primary.tooltip.top .tooltip-arrow {
  border-top-color: #ab8ce4
}

.tooltip-primary+.tooltip.right .tooltip-arrow,
.tooltip-primary.tooltip.right .tooltip-arrow {
  border-right-color: #ab8ce4
}

.tooltip-primary+.tooltip.bottom .tooltip-arrow,
.tooltip-primary.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #ab8ce4
}

.tooltip-primary+.tooltip.left .tooltip-arrow,
.tooltip-primary.tooltip.left .tooltip-arrow {
  border-left-color: #ab8ce4
}

.tooltip-success+.tooltip .tooltip-inner,
.tooltip-success.tooltip .tooltip-inner {
  color: #fff;
  background-color: #00c292
}

.tooltip-success+.tooltip.top .tooltip-arrow,
.tooltip-success.tooltip.top .tooltip-arrow {
  border-top-color: #00c292
}

.tooltip-success+.tooltip.right .tooltip-arrow,
.tooltip-success.tooltip.right .tooltip-arrow {
  border-right-color: #00c292
}

.tooltip-success+.tooltip.bottom .tooltip-arrow,
.tooltip-success.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #00c292
}

.tooltip-success+.tooltip.left .tooltip-arrow,
.tooltip-success.tooltip.left .tooltip-arrow {
  border-left-color: #00c292
}

.tooltip-warning+.tooltip .tooltip-inner,
.tooltip-warning.tooltip .tooltip-inner {
  color: #fff;
  background-color: #fec107
}

.tooltip-warning+.tooltip.top .tooltip-arrow,
.tooltip-warning.tooltip.top .tooltip-arrow {
  border-top-color: #fec107
}

.tooltip-warning+.tooltip.right .tooltip-arrow,
.tooltip-warning.tooltip.right .tooltip-arrow {
  border-right-color: #fec107
}

.tooltip-warning+.tooltip.bottom .tooltip-arrow,
.tooltip-warning.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fec107
}

.tooltip-warning+.tooltip.left .tooltip-arrow,
.tooltip-warning.tooltip.left .tooltip-arrow {
  border-left-color: #fec107
}

.tooltip-info+.tooltip .tooltip-inner,
.tooltip-info.tooltip .tooltip-inner {
  color: #fff;
  background-color: #03a9f3
}

.tooltip-info+.tooltip.top .tooltip-arrow,
.tooltip-info.tooltip.top .tooltip-arrow {
  border-top-color: #03a9f3
}

.tooltip-info+.tooltip.right .tooltip-arrow,
.tooltip-info.tooltip.right .tooltip-arrow {
  border-right-color: #03a9f3
}

.tooltip-info+tooltip.bottom .tooltip-arrow,
.tooltip-info.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #03a9f3
}

.tooltip-info+.tooltip.left .tooltip-arrow,
.tooltip-info.tooltip.left .tooltip-arrow {
  border-left-color: #03a9f3
}

.tooltip-danger+.tooltip .tooltip-inner,
.tooltip-danger.tooltip .tooltip-inner {
  color: #fff;
  background-color: #fb9678
}

.tooltip-danger+.tooltip.top .tooltip-arrow,
.tooltip-danger.tooltip.top .tooltip-arrow {
  border-top-color: #fb9678
}

.tooltip-danger+.tooltip.right .tooltip-arrow,
.tooltip-danger.tooltip.right .tooltip-arrow {
  border-right-color: #fb9678
}

.tooltip-danger+.tooltip.bottom .tooltip-arrow,
.tooltip-danger.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fb9678
}

.tooltip-danger+.tooltip.left .tooltip-arrow,
.tooltip-danger.tooltip.left .tooltip-arrow {
  border-left-color: #fb9678
}

.flotTip {
  padding: 8px 12px;
  background-color: #2b2b2b;
  z-index: 100;
  color: #fff;
  opacity: .9;
  font-size: 13px
}

.popover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, .05)
}

.popover .popover-title {
  border-radius: 0
}

.popover-primary+.popover .popover-title {
  color: #fff;
  background-color: #ab8ce4;
  border-color: #ab8ce4
}

.popover-primary+.popover.bottom .arrow,
.popover-primary+.popover.bottom .arrow:after {
  border-bottom-color: #ab8ce4
}

.popover-success+.popover .popover-title {
  color: #fff;
  background-color: #00c292;
  border-color: #00c292
}

.popover-success+.popover.bottom .arrow,
.popover-success+.popover.bottom .arrow:after {
  border-bottom-color: #00c292
}

.popover-info+.popover .popover-title {
  color: #fff;
  background-color: #03a9f3;
  border-color: #03a9f3
}

.popover-info+.popover.bottom .arrow,
.popover-info+.popover.bottom .arrow:after {
  border-bottom-color: #03a9f3
}

.popover-warning+.popover .popover-title {
  color: #fff;
  background-color: #fec107;
  border-color: #fec107
}

.popover-warning+.popover.bottom .arrow,
.popover-warning+.popover.bottom .arrow:after {
  border-bottom-color: #fec107
}

.popover-danger+.popover .popover-title {
  color: #fff;
  background-color: #fb9678;
  border-color: #fb9678
}

.popover-danger+.popover.bottom .arrow,
.popover-danger+.popover.bottom .arrow:after {
  border-bottom-color: #fb9678
}

.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle
}

.btn-file>input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
  border-radius: 0
}

.fileinput {
  margin-bottom: 9px;
  display: inline-block
}

.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text;
  border-radius: 10px
}

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center
}

.fileinput .thumbnail>img {
  max-height: 100%
}

.fileinput .btn {
  vertical-align: middle
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none
}

.fileinput-inline .fileinput-controls {
  display: inline
}

.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden
}

.form-control .fileinput-filename {
  vertical-align: bottom;
  border-radius: 10px
}

.fileinput.input-group {
  display: table
}

.fileinput.input-group>* {
  position: relative;
  z-index: 2
}

.fileinput.input-group>.btn-file {
  z-index: 1
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%
}

.ms-container .ms-list {
  border-radius: 0;
  box-shadow: none
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  padding: 6px 10px
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  background: #03a9f3
}

.dropzone .dz-message {
  text-align: center;
  margin: 10% 0
}

.editable-input .form-control {
  height: 30px;
  border-radius: 10px
}

.asColorPicker-trigger {
  position: absolute;
  top: 0;
  right: -35px;
  height: 38px;
  width: 37px;
  border: 0
}

.asColorPicker-dropdown {
  max-width: 260px
}

.asColorPicker-clear {
  top: 7px;
  right: 16px
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background: #ff6849;
  color: #fff
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
  background: #03a9f3;
  color: #fff
}

.editable-table+input.error {
  border: '1px solid #danger';
  outline: '0';
  outline-offset: '0'
}

#editable-datatable_wrapper+input:focus,
.editable-table+input,
.editable-table+input:focus {
  border: 1px solid #03a9f3!important;
  outline: 0!important;
  outline-offset: 0!important
}

.editable-table td:focus {
  outline: 0
}

.wizard-steps {
  display: table;
  width: 100%
}

.wizard-steps>li {
  display: table-cell;
  padding: 10px 20px;
  background: #f7fafc
}

.wizard-steps>li span {
  border-radius: 100%;
  border: 1px solid rgba(120, 130, 140, .13);
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 9px;
  margin-right: 8px;
  text-align: center
}

.wizard-content {
  padding: 25px;
  border-color: rgba(120, 130, 140, .13);
  margin-bottom: 30px
}

.wizard-steps>li.current,
.wizard-steps>li.done {
  background: #03a9f3;
  color: #fff
}

.wizard-steps>li.current span,
.wizard-steps>li.done span {
  border-color: #fff;
  color: #fff
}

.wizard-steps>li.current h4,
.wizard-steps>li.done h4 {
  color: #fff
}

.wizard-steps>li.done {
  background: #00c292
}

.wizard-steps>li.error {
  background: #fb9678
}

.wiz-aco .pager {
  margin: 0
}

.sidebar {
  background: #fff
}

.sidebar .sidebar-nav.navbar-collapse {
  padding-left: 0;
  padding-right: 0
}

.sidebar .fa-fw {
  width: 20px;
  text-align: 'left';
  display: none
}

.sidebar .label {
  font-size: 9px;
  width: 18px;
  height: 18px
}

.sidebar .sidebar-search {
  padding: 15px
}

#side-menu li a {
  color: #fff
}

#side-menu>li>a {
  color: #4c5667;
  border-bottom: 2px solid #fff;
  padding: 15px
}

#side-menu>li>a:focus,
#side-menu>li>a:hover {
  background: #f7fafc;
  border-color: #f7fafc
}

#side-menu>li>a.active {
  color: #fff
}

#side-menu ul>li>a:hover {
  color: #02bec9;
  background: #f7fafc
}

#side-menu ul>li>a.active {
  color: #02bec9
}

.sidebar .arrow {
  position: absolute;
  right: 15px;
  top: 13px
}

.sidebar .fa.arrow:before {
  content: "\F105"
}

.sidebar .active>a>.fa.arrow:before {
  content: "\F107"
}

.sidebar .nav-second-level li a {
  padding-left: 37px
}

.sidebar .nav-third-level li a {
  padding-left: 52px
}

@media (min-width:767px) {
  .nav-second-level {
      position: absolute;
      width: 220px
  }
  .nav-second-level li {
      background: #f7fafc
  }
  #side-menu {
      margin-left: 0px
  }
  #side-menu>li>a span.arrow {
      display: none
  }
  .sidebar .nav-second-level li a {
      padding-left: 15px
  }
  .sidebar .nav-third-level li a {
      padding-left: 30px
  }
  #side-menu>li {
      float: left
  }
  #side-menu>li .collapse.in {
      display: none
  }
  #side-menu>li:hover .collapse.in,
  #side-menu>li:hover .nav-second-level {
      display: block;
      position: absolute;
      z-index: 1
  }
  #side-menu .nav-second-level li .nav-third-level.collapse.in,
  .nav-third-level {
      position: absolute;
      width: 220px;
      background: #f7fafc;
      left: 221px;
      top: 0;
      display: none;
      box-shadow: 5px 10px 20px rgba(0, 0, 0, .05)
  }
  #side-menu .nav-second-level li:hover>a {
      color: #ff6849
  }
  #side-menu .nav-second-level li:hover .nav-third-level,
  #side-menu .nav-second-level li:hover .nav-third-level.collapse.in {
      display: block
  }
  #side-menu .nav-second-level.two-li {
      width: 450px;
      background: #f7fafc
  }
  #side-menu .nav-second-level.two-li>li {
      width: 50%;
      float: left
  }
  #side-menu .nav-second-level.two-li>li:hover .nav-third-level {
      z-index: 1;
      box-shadow: 2px 0 20px rgba(0, 0, 0, .05)
  }
}

.open-close {
  display: none!important
}

.btn-white {
  color: #686868;
  background-color: #FFF
}

.btn-white.active,
.btn-white:active,
.btn-white:focus,
.btn-white:hover,
.open .dropdown-toggle.btn-white {
  color: #686868;
  background-color: #fff;
  border: 2px solid rgb(170, 226, 218)
}

.btn-white.active,
.btn-white:active,
.open .dropdown-toggle.btn-white {
  background-image: none
}

.btn-white.disabled,
.btn-white.disabled.active,
.btn-white.disabled:active,
.btn-white.disabled:focus,
.btn-white.disabled:hover,
.btn-white[disabled],
.btn-white[disabled].active,
.btn-white[disabled]:active,
.btn-white[disabled]:focus,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white.active,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:hover {
  background-color: #FFF
}

.btn-white .badge {
  color: #FFF;
  background-color: #686868
}

.sttabs {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  font-weight: 300
}

.sttabs nav {
  text-align: center
}

.sttabs nav ul {
  position: relative;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  padding: 0;
  font-family: Poppins, sans-serif;
  list-style: none;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  flex-flow: row wrap;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center
}

.sttabs nav ul li {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0;
  text-align: center;
  -moz-flex: 1;
  flex: 1 1
}

.sttabs nav a {
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2.5
}

.sttabs nav a span {
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  font-family: Poppins, sans-serif
}

.sttabs nav a:focus {
  outline: 0
}

.sttabs nav li.tab-current a {
  color: #fb9678
}

.tabs-style-bar nav ul li a {
  margin: 0 2px;
  background-color: #f7fafc;
  color: #686868;
  padding: 5px 0;
  transition: background-color .2s, color .2s
}

.tabs-style-bar nav ul li a:focus,
.tabs-style-bar nav ul li a:hover {
  color: #fb9678
}

.tabs-style-bar nav ul li a span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: Poppins, sans-serif
}

.tabs-style-bar nav ul li.tab-current a {
  background: #fb9678;
  color: #fff
}

.tabs-style-iconbox nav {
  background: #f7fafc
}

.tabs-style-iconbox nav ul li a {
  overflow: visible;
  padding: 35px 0;
  line-height: 1;
  transition: color .2s;
  color: #2b2b2b
}

.tabs-style-iconbox nav ul li.tab-current {
  z-index: 1
}

.tabs-style-iconbox nav ul li.tab-current a {
  background: #fb9678;
  color: #fff;
  box-shadow: -1px 0 0 #fff
}

.tabs-style-iconbox nav ul li.tab-current a::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 10px;
  border-top-color: #fb9678;
  content: '';
  pointer-events: none
}

.tabs-style-iconbox nav ul li::after,
.tabs-style-iconbox nav ul li:first-child::before {
  position: absolute;
  top: 20%;
  right: 0;
  z-index: -1;
  width: 1px;
  height: 60%;
  content: ''
}

.tabs-style-iconbox nav ul li:first-child::before {
  right: auto;
  left: 0
}

.tabs-style-iconbox .sticon::before {
  display: block;
  margin: 0 0 .25em
}

.tabs-style-underline nav {
  border: 1px solid rgba(120, 130, 140, .13)
}

.tabs-style-underline nav a {
  padding: 20px 0;
  border-left: 1px solid rgba(120, 130, 140, .13);
  transition: color .2s;
  color: #2b2b2b
}

.tabs-style-underline nav li:last-child a {
  border-right: 1px solid rgba(120, 130, 140, .13)
}

.tabs-style-underline nav li a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: #fb9678;
  content: '';
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  -webkit-transform: translate3d(0, 150%, 0);
  transform: translate3d(0, 150%, 0)
}

.tabs-style-underline nav li.tab-current a::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.tabs-style-linetriangle nav a {
  overflow: visible;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  transition: color .2s
}

.tabs-style-linetriangle nav a span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  padding: 15px 0;
  color: #2b2b2b
}

.tabs-style-linetriangle nav li.tab-current a:after,
.tabs-style-linetriangle nav li.tab-current a:before {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  content: '';
  pointer-events: none
}

.tabs-style-linetriangle nav li.tab-current a:after {
  margin-left: -10px;
  border-width: 10px;
  border-top-color: #fff
}

.tabs-style-linetriangle nav li.tab-current a span {
  color: #fb9678
}

.tabs-style-linetriangle nav li.tab-current a:before {
  margin-left: -11px;
  border-width: 11px;
  border-top-color: rgba(0, 0, 0, .2)
}

.tabs-style-iconfall {
  overflow: visible
}

.tabs-style-iconfall nav {
  max-width: 1200px;
  margin: 0 auto
}

.tabs-style-iconfall nav a {
  display: inline-block;
  overflow: visible;
  padding: 1em 0 2em;
  color: #2b2b2b;
  line-height: 1;
  transition: color .3s cubic-bezier(.7, 0, .3, 1)
}

.tabs-style-iconfall nav a:focus,
.tabs-style-iconfall nav a:hover,
.tabs-style-iconfall nav li.tab-current a {
  color: #fb9678
}

.tabs-style-iconfall nav li::before {
  position: absolute;
  bottom: 1em;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 4px;
  background: #fb9678;
  content: '';
  opacity: 0;
  transition: -webkit-transform .2s ease-in;
  transition: transform .2s ease-in;
  transition: transform .2s ease-in, -webkit-transform .2s ease-in;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1)
}

.tabs-style-iconfall nav li.tab-current::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1)
}

.tabs-style-iconfall nav li.tab-current .sticon::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.tabs-style-iconfall .sticon::before {
  display: block;
  margin: 0 0 .35em;
  opacity: 0;
  font-size: 24px;
  transition: opacity .2s, -webkit-transform .2s;
  transition: transform .2s, opacity .2s;
  transition: transform .2s, opacity .2s, -webkit-transform .2s;
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
  pointer-events: none
}

@media screen and (max-width:58em) {
  .tabs-style-iconfall nav li .sticon::before {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0)
  }
}

.tabs-style-linemove nav {
  background: #f7fafc
}

.tabs-style-linemove nav li:last-child::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #fb9678;
  content: '';
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s
}

.tabs-style-linemove nav li:first-child.tab-current~li:last-child::before {
  -webkit-transform: translate3d(-400%, 0, 0);
  transform: translate3d(-400%, 0, 0)
}

.tabs-style-linemove nav li:nth-child(2).tab-current~li:last-child::before {
  -webkit-transform: translate3d(-300%, 0, 0);
  transform: translate3d(-300%, 0, 0)
}

.tabs-style-linemove nav li:nth-child(3).tab-current~li:last-child::before {
  -webkit-transform: translate3d(-200%, 0, 0);
  transform: translate3d(-200%, 0, 0)
}

.tabs-style-linemove nav li:nth-child(4).tab-current~li:last-child::before {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0)
}

.tabs-style-linemove nav a {
  padding: 30px 0;
  color: #2b2b2b;
  line-height: 1;
  transition: color .3s, -webkit-transform .3s;
  transition: color .3s, transform .3s;
  transition: color .3s, transform .3s, -webkit-transform .3s
}

.tabs-style-linemove nav li.tab-current a {
  color: #fb9678
}

.tabs-style-line nav a {
  padding: 20px 10px;
  box-shadow: inset 0 -2px #d1d3d2;
  color: #686868;
  text-align: 'left';
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  transition: color .3s, box-shadow .3s
}

.tabs-style-line nav a:focus,
.tabs-style-line nav a:hover {
  box-shadow: inset 0 -2px #74777b
}

.tabs-style-line nav li.tab-current a {
  box-shadow: inset 0 -2px #fb9678;
  color: #fb9678
}

@media screen and (max-width:58em) {
  .tabs-style-line nav ul {
      display: block;
      box-shadow: none
  }
  .tabs-style-line nav ul li {
      display: block;
      flex: none
  }
}

.tabs-style-circle {
  overflow: visible
}

.tabs-style-circle nav li {
  margin-top: 60px!important;
  margin-bottom: 60px!important
}

.tabs-style-circle nav li::before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -60px 0 0 -60px;
  width: 120px;
  height: 120px;
  border: 1px solid #fb9678;
  border-radius: 50%;
  content: '';
  opacity: 0;
  transition: opacity .2s, -webkit-transform .2s;
  transition: transform .2s, opacity .2s;
  transition: transform .2s, opacity .2s, -webkit-transform .2s;
  transition-timing-function: cubic-bezier(.7, 0, .3, 1)
}

.tabs-style-circle nav a {
  overflow: visible;
  color: #2b2b2b;
  font-weight: 500;
  font-size: 14;
  line-height: 1.1;
  transition: color .3s cubic-bezier(.7, 0, .3, 1)
}

.tabs-style-circle nav a span {
  display: inline-block
}

.tabs-style-circle nav a:focus,
.tabs-style-circle nav a:hover,
.tabs-style-circle nav li.tab-current a {
  color: #fb9678
}

.tabs-style-circle nav li.tab-current a span {
  -webkit-transform: translate3d(0, 4px, 0);
  transform: translate3d(0, 4px, 0)
}

@media screen and (max-width:58em) {
  .tabs-style-circle nav li::before {
      margin: -40px 0 0 -40px;
      width: 80px;
      height: 80px
  }
}

.tabs-style-circle nav li.tab-current::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1)
}

.tabs-style-circle .icon::before,
.tabs-style-circle nav a span {
  transition: -webkit-transform .3s cubic-bezier(.7, 0, .3, 1);
  transition: transform .3s cubic-bezier(.7, 0, .3, 1);
  transition: transform .3s cubic-bezier(.7, 0, .3, 1), -webkit-transform .3s cubic-bezier(.7, 0, .3, 1)
}

.tabs-style-circle .sticon::before {
  display: block;
  margin: 0;
  pointer-events: none
}

.tabs-style-circle nav li.tab-current .sticon::before {
  -webkit-transform: translate3d(0, -4px, 0);
  transform: translate3d(0, -4px, 0)
}

.tabs-style-shape {
  max-width: 1200px;
  margin: 0 auto
}

.tabs-style-shape nav ul li {
  margin: 0 3em
}

.tabs-style-shape nav ul li:first-child {
  margin-left: 0
}

.tabs-style-shape nav ul li.tab-current {
  z-index: 1
}

.tabs-style-shape nav li a {
  overflow: visible;
  margin: 0 -3em 0 0;
  padding: 0;
  color: #fff;
  font-weight: 500
}

.tabs-style-shape nav li a svg {
  position: absolute;
  left: 100%;
  margin: 0;
  width: 3em;
  height: 100%;
  fill: #bdc2c9
}

.tabs-style-shape nav li:first-child a span {
  padding-left: 2em;
  border-radius: 30px 0 0
}

.tabs-style-shape nav li:last-child a span {
  padding-right: 2em;
  border-radius: 0 30px 0 0
}

.tabs-style-shape nav li a svg:nth-child(2),
.tabs-style-shape nav li:last-child a svg {
  right: 100%;
  left: auto;
  -webkit-transform: scale3d(-1, 1, 1);
  transform: scale3d(-1, 1, 1)
}

.tabs-style-shape nav li a span {
  display: block;
  overflow: hidden;
  padding: .65em 0;
  background-color: #bdc2c9;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tabs-style-shape nav li a:hover span {
  background-color: #fb9678
}

.tabs-style-shape nav li a:hover svg {
  fill: #fb9678
}

.tabs-style-shape nav li a svg {
  pointer-events: none
}

.tabs-style-shape nav li a svg use {
  pointer-events: auto
}

.tabs-style-shape nav li.tab-current a span,
.tabs-style-shape nav li.tab-current a svg {
  transition: none
}

.tabs-style-shape nav li.tab-current a span {
  background: #f7fafc
}

.tabs-style-shape nav li.tab-current a svg {
  fill: #f7fafc
}

.tabs-style-shape .content-wrap {
  background: #f7fafc
}

@media screen and (max-width:58em) {
  .tabs-style-shape nav ul {
      display: block;
      padding-top: 1.5em
  }
  .tabs-style-shape nav ul li {
      display: block;
      margin: -1.25em 0 0;
      flex: none
  }
  .tabs-style-shape nav ul li a {
      margin: 0
  }
  .tabs-style-shape nav ul li svg {
      display: none
  }
  .tabs-style-shape nav ul li a span {
      padding: 1.25em 0 2em!important;
      border-radius: 30px 30px 0 0!important;
      box-shadow: 0 -1px 2px rgba(0, 0, 0, .1);
      line-height: 1
  }
  .tabs-style-shape nav ul li:last-child a span {
      padding: 1.25em 0!important
  }
  .tabs-style-shape nav ul li.tab-current {
      z-index: 1
  }
}

.tabs-style-linebox nav ul li {
  margin: 0 .5em;
  flex: none
}

.tabs-style-linebox nav a {
  padding: 0 1.5em;
  color: #2b2b2b;
  font-weight: 500;
  transition: color .3s
}

.tabs-style-linebox nav a:focus,
.tabs-style-linebox nav a:hover {
  color: #fb9678
}

.tabs-style-linebox nav li.tab-current a {
  color: #fff
}

.tabs-style-linebox nav a::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #d2d8d6;
  content: '';
  transition: background-color .3s, -webkit-transform .3s;
  transition: background-color .3s, transform .3s;
  transition: background-color .3s, transform .3s, -webkit-transform .3s;
  transition-timing-function: ease, cubic-bezier(.7, 0, .3, 1);
  -webkit-transform: translate3d(0, 100%, 0) translate3d(0, -3px, 0);
  transform: translate3d(0, 100%, 0) translate3d(0, -3px, 0)
}

.tabs-style-linebox nav li.tab-current a::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.tabs-style-linebox nav a:focus::after,
.tabs-style-linebox nav a:hover::after,
.tabs-style-linebox nav li.tab-current a::after {
  background: #fb9678
}

@media screen and (max-width:58em) {
  .tabs-style-linebox nav ul {
      display: block;
      box-shadow: none
  }
  .tabs-style-linebox nav ul li {
      display: block;
      flex: none
  }
}

.tabs-style-flip {
  max-width: 1200px;
  margin: 0 auto
}

.tabs-style-flip nav a {
  padding: .5em 0;
  color: #2b2b2b;
  transition: color .3s
}

.tabs-style-flip nav a:focus,
.tabs-style-flip nav a:hover {
  color: #fb9678
}

.tabs-style-flip nav a span {
  text-transform: uppercase;
  letter-spacing: 1px
}

.tabs-style-flip nav a::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  content: '';
  transition: background-color .3s, -webkit-transform .3s;
  transition: transform .3s, background-color .3s;
  transition: transform .3s, background-color .3s, -webkit-transform .3s;
  -webkit-transform: perspective(900px) rotate3d(1, 0, 0, 90deg);
  transform: perspective(900px) rotate3d(1, 0, 0, 90deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-perspective-origin: 50% 100%;
  perspective-origin: 50% 100%
}

.tabs-style-flip nav li.tab-current a {
  color: #fb9678
}

.tabs-style-flip nav li.tab-current a::after {
  background-color: #f7fafc;
  -webkit-transform: perspective(900px) rotate3d(1, 0, 0, 0deg);
  transform: perspective(900px) rotate3d(1, 0, 0, 0deg)
}

.tabs-style-flip .content-wrap {
  background: #f7fafc
}

.tabs-style-circlefill {
  max-width: 800px;
  border: 1px solid #fb9678;
  margin: 0 auto
}

.tabs-style-circlefill nav ul li {
  overflow: hidden;
  border-right: 1px solid #fb9678
}

.tabs-style-circlefill nav li a {
  padding: 1.5em 0;
  color: #fff;
  font-size: 1.25em
}

.tabs-style-circlefill nav li:first-child {
  border-left: none
}

.tabs-style-circlefill nav li:last-child {
  border: none
}

.tabs-style-circlefill nav li::before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  width: 80px;
  height: 80px;
  border: 1px solid #fb9678;
  border-radius: 50%;
  background: #fb9678;
  content: '';
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s
}

.tabs-style-circlefill nav li.tab-current::before {
  -webkit-transform: scale3d(2.5, 2.5, 1);
  transform: scale3d(2.5, 2.5, 1)
}

.tabs-style-circlefill nav a {
  transition: color .3s
}

.tabs-style-circlefill nav a span {
  display: none
}

.tabs-style-circlefill nav li.tab-current a {
  color: #fff
}

.tabs-style-circlefill .icon::before {
  display: block;
  margin: 0;
  pointer-events: none
}

.tabs-style-circlefill .content-wrap {
  border-top: 1px solid #fb9678
}

.content-wrap {
  position: relative
}

.content-wrap section {
  display: none;
  margin: 0 auto;
  padding: 25px;
  min-height: 150px
}

.content-wrap section p {
  margin: 0;
  padding: .75em 0
}

.content-wrap section.content-current {
  display: block
}

.no-js .content-wrap section {
  display: block;
  padding-bottom: 2em;
  border-bottom: 1px solid rgba(255, 255, 255, .6)
}

.no-flexbox nav ul {
  display: block
}

.no-flexbox nav ul li {
  min-width: 15%;
  display: inline-block
}

@media screen and (max-width:58em) {
  .sttabs nav a span {
      display: none
  }
  .sttabs nav a:before {
      margin-right: 0
  }
}

@media (max-width:1350px) {
  .carousel .item h3 {
      font-size: 17px;
      height: 90px
  }
  .inbox-center a {
      width: 400px
  }
}

.search-listing {
  padding: 0;
  margin: 0
}

.search-listing li {
  list-style: none;
  padding: 15px 0;
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.search-listing li h3 {
  margin: 0;
  font-size: 18px
}

.search-listing li h3 a {
  color: #03a9f3
}

.search-listing li h3 a:hover {
  text-decoration: underline
}

.search-listing li a {
  color: #00c292
}

.megamenu {
  left: 0;
  right: 0;
  width: 100%
}

.mega-dropdown {
  position: static!important
}

.mega-dropdown-menu {
  padding: 20px;
  width: 100%;
  -webkit-box-shadow: none;
  border: 0;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .2)!important
}

.mega-dropdown-menu>li>ul {
  padding: 0;
  margin: 0
}

.mega-dropdown-menu>li>ul>li {
  list-style: none
}

.mega-dropdown-menu>li>ul>li>a {
  display: block;
  padding: 8px 0;
  clear: both;
  line-height: 1.428571429;
  color: #686868;
  white-space: normal
}

.mega-dropdown-menu>li>ul>li>a:focus,
.mega-dropdown-menu>li>ul>li>a:hover {
  text-decoration: none;
  color: #ff6849
}

.mega-dropdown-menu .dropdown-header {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  margin-top: 12px
}

.mega-dropdown-menu li.demo-box a {
  color: #fff;
  display: block
}

.mega-dropdown-menu li.demo-box a:hover {
  opacity: .8
}

a.dt-button,
button.dt-button,
div.dt-button {
  background: #03a9f3;
  color: #fff;
  border-color: #03a9f3
}

a.dt-button:hover,
button.dt-button:hover,
div.dt-button:hover {
  background: #03a9f3
}

a.dt-button:hover:not(.disabled),
button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled) {
  background: #f7fafc;
  color: #2b2b2b;
  border-color: rgba(120, 130, 140, .13)
}

.dataTables_filter input {
  border: 1px solid rgba(120, 130, 140, .13)
}

table.dataTable.display tbody tr.even>.sorting_1,
table.dataTable.display tbody tr.odd>.sorting_1,
table.dataTable.display tbody tr:hover>.sorting_1,
table.dataTable.order-column.hover tbody tr:hover>.sorting_1,
table.dataTable.order-column.stripe tbody tr.even>.sorting_1,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
  background: 0 0
}

.note-editor .panel-heading {
  padding: 6px 10px 10px
}

.page-aside {
  position: relative
}

.left-aside {
  position: absolute;
  background: #fff;
  border-right: 1px solid rgba(120, 130, 140, .13);
  padding: 20px;
  width: 250px;
  height: 100%
}

.right-aside {
  padding: 20px;
  margin-left: 250px
}

.right-aside .contact-list td {
  vertical-align: middle;
  padding: 25px 10px
}

.right-aside .contact-list td img {
  width: 30px
}

.list-style-none {
  margin: 0;
  padding: 0
}

.list-style-none li {
  list-style: none;
  margin: 0
}

.list-style-none li.box-label a {
  font-weight: 500
}

.list-style-none li.divider {
  margin: 10px 0;
  height: 1px;
  background: rgba(120, 130, 140, .13)
}

.list-style-none li a {
  padding: 15px 10px;
  display: block;
  color: #686868
}

.list-style-none li a:hover {
  color: #ff6849
}

.list-style-none li a span {
  float: right
}

.chat-main-box {
  position: relative;
  background: #fff;
  overflow: hidden
}

.chat-main-box .chat-left-aside {
  position: absolute;
  width: 250px;
  z-index: 9;
  top: 0;
  border-right: 1px solid rgba(120, 130, 140, .13)
}

.chat-main-box .chat-left-aside .open-panel {
  display: none;
  cursor: pointer;
  position: absolute;
  left: -webkit-calc(99%);
  top: 50%;
  z-index: 100;
  background-color: #fff;
  box-shadow: 1px 0 3px rgba(0, 0, 0, .2);
  border-radius: 0 100px 100px 0;
  line-height: 1;
  padding: 15px 8px 15px 4px
}

.chat-main-box .chat-left-aside .chat-left-inner .form-control {
  height: 60px
}

.chat-main-box .chat-left-aside .chat-left-inner .style-none {
  padding: 0
}

.chat-main-box .chat-left-aside .chat-left-inner .style-none li {
  list-style: none;
  overflow: hidden
}

.chat-main-box .chat-left-aside .chat-left-inner .style-none li a {
  padding: 20px
}

.chat-main-box .chat-left-aside .chat-left-inner .style-none li a.active,
.chat-main-box .chat-left-aside .chat-left-inner .style-none li a:hover {
  background: #f7fafc
}

.chat-main-box .chat-right-aside {
  margin-left: 250px
}

.chat-main-box .chat-right-aside .chat-list {
  max-height: none;
  height: 100%;
  padding-top: 40px
}

.chat-main-box .chat-right-aside .chat-list .chat-text {
  border-radius: 6px
}

.chat-main-box .chat-right-aside .send-chat-box {
  position: relative
}

.chat-main-box .chat-right-aside .send-chat-box .form-control {
  border: none;
  border-top: 1px solid rgba(120, 130, 140, .13);
  resize: none;
  height: 80px;
  padding-right: 180px
}

.chat-main-box .chat-right-aside .send-chat-box .form-control:focus {
  border-color: rgba(120, 130, 140, .13)
}

.chat-main-box .chat-right-aside .send-chat-box .custom-send {
  position: absolute;
  right: 20px;
  bottom: 10px
}

.chat-main-box .chat-right-aside .send-chat-box .custom-send .cst-icon {
  color: #686868;
  margin-right: 10px
}

.el-element-overlay .white-box {
  padding: 0
}

.el-element-overlay .el-card-item {
  position: relative;
  padding-bottom: 25px
}

.el-element-overlay .el-card-item .el-card-avatar {
  margin-bottom: 15px
}

.el-element-overlay .el-card-item .el-card-content {
  text-align: center
}

.el-element-overlay .el-card-item .el-card-content h3 {
  margin: 0
}

.el-element-overlay .el-card-item .el-card-content a {
  color: #686868
}

.el-element-overlay .el-card-item .el-card-content a:hover {
  color: #ff6849
}

.el-element-overlay .el-card-item .el-overlay-1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default
}

.el-element-overlay .el-card-item .el-overlay-1 img {
  display: block;
  position: relative;
  transition: all .4s linear;
  width: 100%;
  height: auto
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info {
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  background-color: transparent;
  filter: alpha(opacity=0);
  transition: all .2s ease-in-out;
  padding: 0;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%) translateZ(0);
  -webkit-transform: translateY(-50%) translateZ(0);
  -ms-transform: translateY(-50%) translateZ(0)
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info>li {
  list-style: none;
  display: inline-block;
  margin: 0 3px
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info>li a {
  border-color: #fff;
  color: #fff;
  padding: 12px 15px 10px
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info>li a:hover {
  background: #fb9678;
  border-color: #fb9678
}

.el-element-overlay .el-card-item .el-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, .7);
  transition: all .4s ease-in-out
}

.el-element-overlay .el-card-item .el-overlay-1:hover .el-overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.el-element-overlay .el-card-item .el-overlay-1 .scrl-dwn {
  top: -100%
}

.el-element-overlay .el-card-item .el-overlay-1 .scrl-up {
  top: 100%;
  height: 0
}

.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-dwn {
  top: 0
}

.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-up {
  top: 0;
  height: 100%
}

.login-sidebar {
  position: absolute;
  right: 0;
  margin-top: 0;
  height: 100%
}

.common-list {
  margin: 0;
  padding: 0
}

.common-list li {
  list-style: none;
  display: block
}

.common-list li a {
  padding: 12px 0;
  color: #686868;
  display: block
}

.common-list li a:hover {
  color: #ff6849
}

.color-table.primary-table thead th {
  background-color: #ab8ce4;
  color: #fff
}

.color-table.success-table thead th {
  background-color: #00c292;
  color: #fff
}

.color-table.info-table thead th {
  background-color: #03a9f3;
  color: #fff
}

.color-table.warning-table thead th {
  background-color: #fec107;
  color: #fff
}

.color-table.danger-table thead th {
  background-color: #fb9678;
  color: #fff
}

.color-table.inverse-table thead th {
  background-color: #4c5667;
  color: #fff
}

.color-table.dark-table thead th {
  background-color: #2b2b2b;
  color: #fff
}

.color-table.red-table thead th {
  background-color: #fb3a3a;
  color: #fff
}

.color-table.purple-table thead th {
  background-color: #9675ce;
  color: #fff
}

.color-table.muted-table thead th {
  background-color: #98a6ad;
  color: #fff
}

.color-bordered-table.primary-bordered-table {
  border: 2px solid #ab8ce4
}

.color-bordered-table.primary-bordered-table thead th {
  background-color: #ab8ce4;
  color: #fff
}

.color-bordered-table.success-bordered-table {
  border: 2px solid #00c292
}

.color-bordered-table.success-bordered-table thead th {
  background-color: #00c292;
  color: #fff
}

.color-bordered-table.info-bordered-table {
  border: 2px solid #03a9f3
}

.color-bordered-table.info-bordered-table thead th {
  background-color: #03a9f3;
  color: #fff
}

.color-bordered-table.warning-bordered-table {
  border: 2px solid #fec107
}

.color-bordered-table.warning-bordered-table thead th {
  background-color: #fec107;
  color: #fff
}

.color-bordered-table.danger-bordered-table {
  border: 2px solid #fb9678
}

.color-bordered-table.danger-bordered-table thead th {
  background-color: #fb9678;
  color: #fff
}

.color-bordered-table.inverse-bordered-table {
  border: 2px solid #4c5667
}

.color-bordered-table.inverse-bordered-table thead th {
  background-color: #4c5667;
  color: #fff
}

.color-bordered-table.dark-bordered-table {
  border: 2px solid #2b2b2b
}

.color-bordered-table.dark-bordered-table thead th {
  background-color: #2b2b2b;
  color: #fff
}

.color-bordered-table.red-bordered-table {
  border: 2px solid #fb3a3a
}

.color-bordered-table.red-bordered-table thead th {
  background-color: #fb3a3a;
  color: #fff
}

.color-bordered-table.purple-bordered-table {
  border: 2px solid #9675ce
}

.color-bordered-table.purple-bordered-table thead th {
  background-color: #9675ce;
  color: #fff
}

.color-bordered-table.muted-bordered-table {
  border: 2px solid #98a6ad
}

.color-bordered-table.muted-bordered-table thead th {
  background-color: #98a6ad;
  color: #fff
}

.full-color-table.full-primary-table {
  background-color: rgba(171, 140, 228, .8)
}

.full-color-table.full-primary-table thead th {
  background-color: #ab8ce4;
  border: 0!important;
  color: #fff
}

.full-color-table.full-primary-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-primary-table tr:hover {
  background-color: #ab8ce4
}

.full-color-table.full-success-table {
  background-color: rgba(0, 194, 146, .8)
}

.full-color-table.full-success-table thead th {
  background-color: #00c292;
  border: 0!important;
  color: #fff
}

.full-color-table.full-success-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-success-table tr:hover {
  background-color: #00c292
}

.full-color-table.full-info-table {
  background-color: rgba(3, 169, 243, .8)
}

.full-color-table.full-info-table thead th {
  background-color: #03a9f3;
  border: 0!important;
  color: #fff
}

.full-color-table.full-info-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-info-table tr:hover {
  background-color: #03a9f3
}

.full-color-table.full-warning-table {
  background-color: rgba(254, 193, 7, .8)
}

.full-color-table.full-warning-table thead th {
  background-color: #fec107;
  border: 0!important;
  color: #fff
}

.full-color-table.full-warning-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-warning-table tr:hover {
  background-color: #fec107
}

.full-color-table.full-danger-table {
  background-color: rgba(251, 150, 120, .8)
}

.full-color-table.full-danger-table thead th {
  background-color: #fb9678;
  border: 0!important;
  color: #fff
}

.full-color-table.full-danger-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-danger-table tr:hover {
  background-color: #fb9678
}

.full-color-table.full-inverse-table {
  background-color: rgba(76, 86, 103, .8)
}

.full-color-table.full-inverse-table thead th {
  background-color: #4c5667;
  border: 0!important;
  color: #fff
}

.full-color-table.full-inverse-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-inverse-table tr:hover {
  background-color: #4c5667
}

.full-color-table.full-dark-table {
  background-color: rgba(43, 43, 43, .8)
}

.full-color-table.full-dark-table thead th {
  background-color: #2b2b2b;
  border: 0!important;
  color: #fff
}

.full-color-table.full-dark-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-dark-table tr:hover {
  background-color: #2b2b2b
}

.full-color-table.full-red-table {
  background-color: rgba(251, 58, 58, .8)
}

.full-color-table.full-red-table thead th {
  background-color: #fb3a3a;
  border: 0!important;
  color: #fff
}

.full-color-table.full-red-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-red-table tr:hover {
  background-color: #fb3a3a
}

.full-color-table.full-purple-table {
  background-color: rgba(150, 117, 206, .8)
}

.full-color-table.full-purple-table thead th {
  background-color: #9675ce;
  border: 0!important;
  color: #fff
}

.full-color-table.full-purple-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-purple-table tr:hover {
  background-color: #9675ce
}

.full-color-table.full-muted-table {
  background-color: rgba(152, 166, 173, .8)
}

.full-color-table.full-muted-table thead th {
  background-color: #98a6ad;
  border: 0!important;
  color: #fff
}

.full-color-table.full-muted-table tbody td {
  border: 0!important;
  color: #fff
}

.full-color-table.full-muted-table tr:hover {
  background-color: #98a6ad
}

.floating-labels .form-group {
  position: relative
}

.floating-labels .form-control {
  font-size: 20px;
  padding: 10px 10px 10px 0;
  display: block;
  border: none;
  border-bottom: 1px solid #e4e7ea
}

.floating-labels select.form-control>option {
  font-size: 14px
}

.has-error .form-control {
  border-bottom: 1px solid #fb9678
}

.has-warning .form-control {
  border-bottom: 1px solid #fec107
}

.has-success .form-control {
  border-bottom: 1px solid #00c292
}

.floating-labels .form-control:focus {
  outline: 0;
  border: none
}

.floating-labels label {
  color: #686868;
  font-size: 16px;
  position: absolute;
  cursor: auto;
  top: 10px;
  transition: .2s ease all;
  -moz-transition: .2s ease all;
  -webkit-transition: .2s ease all
}

.floating-labels .form-control:focus~label,
.floating-labels .form-control:valid~label {
  top: -20px;
  font-size: 12px;
  color: #ab8ce4
}

.floating-labels .bar {
  position: relative;
  display: block
}

.floating-labels .bar:after,
.floating-labels .bar:before {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #ab8ce4;
  transition: .2s ease all;
  -moz-transition: .2s ease all;
  -webkit-transition: .2s ease all
}

.floating-labels .bar:before {
  left: 50%
}

.floating-labels .bar:after {
  right: 50%
}

.floating-labels .form-control:focus~.bar:after,
.floating-labels .form-control:focus~.bar:before {
  width: 50%
}

.floating-labels .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: .5
}

.floating-labels .input-lg,
.floating-labels .input-lg~label {
  font-size: 24px
}

.floating-labels .input-sm,
.floating-labels .input-sm~label {
  font-size: 16px
}

.has-warning .bar:after,
.has-warning .bar:before {
  background: #fec107
}

.has-success .bar:after,
.has-success .bar:before {
  background: #00c292
}

.has-error .bar:after,
.has-error .bar:before {
  background: #fb9678
}

.has-warning .form-control:focus~label,
.has-warning .form-control:valid~label {
  color: #fec107
}

.has-success .form-control:focus~label,
.has-success .form-control:valid~label {
  color: #00c292
}

.has-error .form-control:focus~label,
.has-error .form-control:valid~label {
  color: #fb9678
}

.has-feedback label~.t-0 {
  top: 0
}

.table.dataTable,
table.dataTable {
  width: 99.8%!important
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
  float: none;
  padding-left: 10px
}

.re ul.two-part li i,
.re ul.two-part li span {
  font-size: 36px
}

.bg-light h4 {
  font-weight: 700
}

.agent-contact,
.pro-desc {
  font-size: 12px
}

.form-agent-inq .form-group {
  margin-bottom: 10px
}

.agent-info {
  max-height: 358px;
  height: 358px;
  background: #f7fafc
}

.pro-list {
  margin-top: 15px
}

.pro-detail,
.pro-img {
  display: table-cell;
  vertical-align: top
}

.pro-detail h5 a {
  color: #686868;
  line-height: 20px;
  font-weight: 500
}

.pro-box .pro-list-img {
  display: block;
  height: 210px;
  position: relative;
  overflow: hidden
}

.pro-box .pro-label {
  position: absolute;
  text-transform: uppercase;
  top: 0;
  right: 0;
  border-radius: 2px;
  padding: 5px;
  font-size: 80%
}

.pro-col-label {
  padding: 7px;
  width: 26%;
  display: block;
  margin-top: -15px;
  margin-left: 37%;
  border: 1px solid rgba(120, 130, 140, .13);
  text-transform: uppercase
}

.pro-box .pro-label-img {
  position: absolute;
  top: 30px;
  right: 30px
}

.pro-box.pro-horizontal pro-content {
  width: 100%;
  height: 210px
}

.pro-content .pro-list-details {
  height: 138px;
  max-height: 142px;
  border-bottom: 1px solid rgba(120, 130, 140, .13);
  border-right: 1px solid rgba(120, 130, 140, .13)
}

.pro-content .pro-list-info {
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.pro-agent .agent-name h5,
.pro-agent .agent-name small,
.pro-agent-col-3 .agent-name h5,
.pro-agent-col-3 .agent-name small,
.pro-content .pro-list-details h3,
.pro-content .pro-list-details h4,
.pro-content-3-col .pro-list-details h3,
.pro-content-3-col .pro-list-details h4,
.pro-content-3-col .pro-list-details h4 small,
.pro-list-info ul.pro-info li,
.pro-list-info-3-col ul.pro-info li,
.pro-location span,
ul.pro-info li span.label {
  font-weight: 500
}

.pro-list-info ul.pro-info,
.pro-list-info-3-col ul.pro-info {
  padding: 16px 10px 10px;
  list-style: none
}

.pro-list-info ul.pro-info li {
  padding: 10px 0 10px 20px;
  font-size: 12px
}

ul.pro-info li span.label {
  width: 25px;
  height: 25px;
  padding: 8px;
  border-radius: 50%;
  margin-top: -4px;
  margin-right: 15px;
  font-size: 12px
}

ul.pro-amenities li span img,
ul.pro-info li span img {
  margin-top: -8px;
  padding-right: 12px
}

.pro-agent .agent-img a img,
.pro-agent-col-3 .agent-img a img {
  border: 3px solid #fff;
  box-shadow: 1px 1px 1px rgba(120, 130, 140, .13)
}

.pro-agent .agent-img,
.pro-agent .agent-name,
.pro-agent-col-3 .agent-img,
.pro-agent-col-3 .agent-name {
  float: left
}

.pro-agent .agent-img {
  padding-top: 12px
}

.pro-agent .agent-name {
  padding: 10px 0 0 15px
}

.pro-location span {
  padding-top: 27px
}

.pro-content-3-col {
  padding: 15px;
  background: #f7fafc
}

.pro-content-3-col .pro-list-details h4 small {
  color: #fb9678
}

.pro-list-info-3-col ul.pro-info li {
  padding: 10px 5px
}

.pro-agent-col-3 .agent-img {
  padding: 15px
}

.pro-agent-col-3 .agent-name {
  padding: 15px 15px 15px 5px
}

ul.pro-amenities {
  list-style: none;
  padding: 8px 0
}

ul.pro-amenities li {
  padding: 10px 0;
  font-size: 12px
}

ul.pro-amenities li span i {
  padding-right: 12px
}

.pro-rd .table>tbody>tr>td:first-child {
  font-weight: 500
}

.pro-rd .table>tbody>tr>td,
.pro-rd .table>tbody>tr>th {
  border: none;
  padding: 8px 8px 8px 0;
  font-size: 12px
}

.pd-agent-info {
  max-height: 200px;
  height: 200px;
  background: #f7fafc;
  margin-top: 15px
}

.pd-agent-contact,
.pd-agent-inq {
  padding: 25px
}

.pro-add-form .checkbox label,
.pro-add-form .radio label {
  font-weight: 100
}

.register-box {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 2%
}

.step-register {
  position: absolute;
  height: 100%
}

#sortable_panels .panel-heading {
  cursor: move
}

.icheck-list,
.icolors {
  padding: 0;
  margin: 0;
  list-style: none
}



.icolors>li:first-child {
  margin-left: 0
}

.icolors>li.orange {
  background: #fb9678
}

.icolors>li.yellow {
  background: #fec107
}

.icolors>li.info {
  background: #03a9f3
}

.icolors>li.green {
  background: #00c292
}

.icolors>li.red {
  background: #fb3a3a
}

.icolors>li.purple {
  background: #9675ce
}

.icolors>li.blue {
  background: #02bec9
}

.icheck-list {
  float: 'left';
  padding-right: 50px;
  padding-top: 10px
}

.icheck-list li {
  padding-bottom: 5px
}

.icheck-list li label {
  padding-left: 10px
}

.default-steps .column-step {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #edf1f5
}

.default-steps .column-step.active {
  background: #03a9f3
}

.default-steps .column-step.active .step-number,
.default-steps .step-number {
  font-size: 24px;
  background: #03a9f3;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  margin: auto auto 10px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px
}

.default-steps .column-step.active .step-number {
  background: #fff;
  color: #686868
}

.default-steps .step-title {
  font-size: 24px;
  font-weight: 100
}

.default-steps .column-step.active .step-info,
.default-steps .column-step.active .step-title {
  color: #fff
}

.thin-steps .column-step {
  padding: 20px;
  background: #edf1f5
}

.thin-steps .column-step.active {
  background: #fb9678
}

.thin-steps .column-step.active .step-number {
  background: #fff;
  color: #686868
}

.thin-steps .step-title {
  font-size: 24px;
  font-weight: 100;
  padding-left: 60px;
  margin-top: -2px
}

.thin-steps .column-step.active .step-info,
.thin-steps .column-step.active .step-title {
  color: #fff
}

.thin-steps .step-info {
  padding-left: 60px;
  margin-top: -5px
}

.steps-no-bg .column-step {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center
}

.steps-no-bg .column-step.active .step-number,
.steps-no-bg .step-number {
  font-size: 24px;
  background: #fff;
  color: #686868;
  border: 1px solid #686868;
  border-radius: 50%;
  display: inline-block;
  margin: auto auto 10px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px
}

.steps-no-bg .column-step.active .step-number {
  background: #fff;
  color: #03a9f3;
  border: 1px solid #03a9f3
}

.steps-no-bg .step-title {
  font-size: 24px;
  font-weight: 100
}

.steps-no-bg .column-step.active .step-info,
.steps-no-bg .column-step.active .step-title {
  color: #03a9f3
}

.thin-steps-no-bg .column-step {
  padding: 20px
}

.thin-steps-no-bg .column-step.active .step-number {
  background: #fff;
  color: #fb9678;
  border: 1px solid #fb9678
}

.thin-steps-no-bg .step-title {
  font-size: 24px;
  font-weight: 100;
  padding-left: 60px;
  margin-top: -2px
}

.thin-steps-no-bg .column-step.active .step-info,
.thin-steps-no-bg .column-step.active .step-title {
  color: #fb9678
}

.thin-steps-no-bg .step-info {
  padding-left: 60px;
  margin-top: -5px
}

.numbered-bg .column-step {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  height: 160px;
  background: #edf1f5
}

.numbered-bg .column-step.active {
  background: #03a9f3
}

.numbered-bg .column-step.active .step-number,
.numbered-bg .step-number {
  font-size: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 120px;
  color: #e4e7ea;
  z-index: 1
}

.numbered-bg .column-step.active .step-number {
  color: #0298da
}

.numbered-bg .step-title {
  font-size: 24px;
  font-weight: 100;
  padding-top: 18px
}

.numbered-bg .step-info,
.numbered-bg .step-title {
  z-index: 3;
  position: relative
}

.numbered-bg .column-step.active .step-info,
.numbered-bg .column-step.active .step-title {
  color: #fff
}

.thin-steps-numbered-bg .column-step {
  padding: 20px;
  text-align: center;
  background: #edf1f5
}

.thin-steps-numbered-bg .column-step.active {
  background: #03a9f3
}

.thin-steps-numbered-bg .column-step.active .step-number,
.thin-steps-numbered-bg .step-number {
  font-size: 120px;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 75px;
  color: #e4e7ea;
  z-index: 1
}

.thin-steps-numbered-bg .column-step.active .step-number {
  color: #0298da
}

.thin-steps-numbered-bg .step-title {
  font-size: 24px;
  font-weight: 100
}

.thin-steps-numbered-bg .step-info,
.thin-steps-numbered-bg .step-title {
  z-index: 3;
  position: relative
}

.thin-steps-numbered-bg .column-step.active .step-info,
.thin-steps-numbered-bg .column-step.active .step-title {
  color: #fff
}

.line-steps .column-step {
  padding: 30px 0;
  text-align: center
}

.line-steps .step-number {
  font-size: 20px;
  background: #fff;
  border-radius: 50%!important;
  display: inline-block;
  margin: auto auto 14px;
  border: 3px solid #e4e7ea;
  position: relative;
  height: 40px;
  width: 40px;
  z-index: 3;
  line-height: 37px
}

.line-steps .step-title {
  font-size: 20px;
  font-weight: 100;
  position: relative
}

.line-steps .step-title:after,
.line-steps .step-title:before {
  content: '';
  height: 3px;
  width: 50%;
  position: absolute;
  background-color: #e4e7ea;
  top: -32px;
  z-index: 1;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%)
}

.line-steps .step-title:after {
  left: 50%
}

.line-steps .step-title:before {
  right: 50%
}

.line-steps .finish .step-title:after,
.line-steps .start .step-title:before {
  content: none
}

.line-steps .start .step-title:after {
  background-color: #03a9f3
}

.line-steps .start .step-number {
  color: #03a9f3;
  border-color: #03a9f3
}

.line-steps .start .step-info,
.line-steps .start .step-title {
  color: #686868
}

.line-steps .active .step-title:after,
.line-steps .active .step-title:before {
  background-color: #03a9f3
}

.line-steps .active .step-number {
  color: #03a9f3;
  border-color: #03a9f3;
  -webkit-transform: scale(1.6);
  transform: scale(1.6)
}

.line-steps .active .step-info,
.line-steps .active .step-title {
  color: #686868
}

.line-steps .upcoming .step-title:after,
.line-steps .upcoming .step-title:before {
  background-color: #03a9f3
}

.line-steps .upcoming .step-number {
  color: #03a9f3;
  border-color: #03a9f3
}

.line-steps .upcoming .step-info,
.line-steps .upcoming .step-title {
  color: #686868
}

.line-steps .finish .step-number {
  color: #e4e7ea
}

.line-steps .finish .step-info,
.line-steps .finish .step-title {
  color: #686868
}

.ribbon-wrapper,
.ribbon-wrapper-bottom,
.ribbon-wrapper-reverse,
.ribbon-wrapper-right-bottom {
  position: relative;
  background: #edf1f5;
  padding: 50px 15px 15px 50px
}

.ribbon-overflow {
  overflow: hidden
}

.ribbon-wrapper-reverse {
  padding: 50px 50px 15px 15px
}

.ribbon-wrapper-bottom {
  padding: 15px 15px 50px 50px
}

.ribbon-wrapper-right-bottom {
  padding: 15px 50px 50px 15px
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: 'left';
  position: absolute;
  top: 12px;
  left: -2px;
  color: #fff
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border: 15px solid #2b2b2b;
  border-right: 10px solid transparent
}

.ribbon-right {
  left: auto;
  right: -2px
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #2b2b2b;
  border-left: 10px solid transparent
}

.ribbon-vertical-l,
.ribbon-vertical-r {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px
}

.ribbon-vertical-r {
  left: auto;
  right: 12px
}

.ribbon-bookmark.ribbon-vertical-l:before,
.ribbon-bookmark.ribbon-vertical-r:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #2b2b2b;
  border-bottom: 10px solid transparent
}

.ribbon-badge {
  top: 15px;
  overflow: hidden;
  left: -90px;
  width: 100%;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.ribbon-badge.ribbon-right {
  left: auto;
  right: -90px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: 15px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.ribbon-badge.ribbon-right.ribbon-bottom {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.ribbon-corner {
  top: 0;
  left: 0;
  background-color: transparent!important;
  padding: 6px 0 0 10px
}

.ribbon-corner i {
  position: relative
}

.ribbon-corner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 30px solid transparent;
  border-top-color: #ff6849;
  border-left-color: #ff6849
}

.ribbon-corner.ribbon-right:before {
  right: 0;
  left: auto;
  border-right-color: #526069;
  border-left-color: transparent
}

.ribbon-corner.ribbon-right {
  right: 0;
  left: auto;
  padding: 6px 10px 0 0
}

.ribbon-corner.ribbon-bottom:before {
  top: auto;
  bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #526069
}

.ribbon-corner.ribbon-bottom {
  bottom: 0;
  top: auto;
  padding: 0 10px 6px
}

.ribbon-custom {
  background: #ff6849
}

.ribbon-bookmark.ribbon-right.ribbon-custom:before {
  border-right-color: #ff6849;
  border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-custom:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-custom:before {
  border-right-color: #ff6849;
  border-bottom-color: transparent
}

.ribbon-primary {
  background: #ab8ce4
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #ab8ce4 transparent #ab8ce4 #ab8ce4
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #ab8ce4;
  border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-primary:before {
  border-right-color: #ab8ce4;
  border-bottom-color: transparent
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #ab8ce4;
  border-left-color: #ab8ce4
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #ab8ce4;
  border-left-color: transparent
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ab8ce4
}

.ribbon-success {
  background: #00c292
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #00c292 transparent #00c292 #00c292
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #00c292;
  border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-success:before {
  border-right-color: #00c292;
  border-bottom-color: transparent
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #00c292;
  border-left-color: #00c292
}

.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #00c292;
  border-left-color: transparent
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #00c292
}

.ribbon-info {
  background: #03a9f3
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #03a9f3 transparent #03a9f3 #03a9f3
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #03a9f3;
  border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-info:before {
  border-right-color: #03a9f3;
  border-bottom-color: transparent
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #03a9f3;
  border-left-color: #03a9f3
}

.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #03a9f3;
  border-left-color: transparent
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #03a9f3
}

.ribbon-warning {
  background: #fec107
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #fec107 transparent #fec107 #fec107
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #fec107;
  border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-warning:before {
  border-right-color: #fec107;
  border-bottom-color: transparent
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #fec107;
  border-left-color: #fec107
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #fec107;
  border-left-color: transparent
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #fec107
}

.ribbon-danger {
  background: #fb9678
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #fb9678 transparent #fb9678 #fb9678
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #fb9678;
  border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-danger:before {
  border-right-color: #fb9678;
  border-bottom-color: transparent
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #fb9678;
  border-left-color: #fb9678
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #fb9678;
  border-left-color: transparent
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #fb9678
}

.ribbon-default {
  background: #2b2b2b
}

.ribbon-bookmark.ribbon-default:before {
  border-color: #2b2b2b transparent #2b2b2b #2b2b2b
}

.ribbon-bookmark.ribbon-right.ribbon-default:before {
  border-right-color: #2b2b2b;
  border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-default:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-default:before {
  border-right-color: #2b2b2b;
  border-bottom-color: transparent
}

.ribbon-default.ribbon-corner:before {
  border-top-color: #2b2b2b;
  border-left-color: #2b2b2b
}

.ribbon-default.ribbon-corner.ribbon-right:before {
  border-right-color: #2b2b2b;
  border-left-color: transparent
}

.ribbon-default.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #2b2b2b
}

.bootstrap-switch,
.bootstrap-switch .bootstrap-switch-container {
  border-radius: 2px
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px
}

.bootstrap-switch .bootstrap-switch-handle-off {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  color: #fff;
  background: #ab8ce4
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
  color: #fff;
  background: #03a9f3
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  color: #fff;
  background: #00c292
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  color: #fff;
  background: #fec107
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  color: #fff;
  background: #fb9678
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  color: #2b2b2b;
  background: #e4e7ea
}

.lobipanel .panel-heading .dropdown .dropdown-menu>li>a .panel-control-icon,
.lobipanel>.panel-heading .dropdown .dropdown-toggle .panel-control-icon {
  line-height: 1px
}

.lobipanel .panel-heading .dropdown .dropdown-menu>li>a {
  color: #2b2b2b
}

.lobipanel .panel-heading .dropdown .dropdown-menu {
  box-shadow: none!important
}

.lobipanel .panel-heading .dropdown .dropdown-menu>li>a:focus:hover,
.lobipanel .panel-heading .dropdown .dropdown-menu>li>a:hover {
  background: 0 0;
  text-shadow: none;
  opacity: .6
}

.lobipanel-placeholder {
  background-color: #02bec9;
  opacity: .1;
  border: 1px dashed #2b2b2b
}

.dp-selected[style] {
  background-color: #01c0c8!important
}

.grid-stack-item-content {
  background: #fff;
  color: #2b2b2b;
  font-family: Poppins, sans-serif;
  text-align: center;
  font-size: 20px
}

.grid-stack-item-content .fa {
  font-size: 64px;
  display: block;
  margin: 20px 0 10px
}

@media (max-width:1350px) {
  .carousel .item h3 {
      font-size: 17px;
      height: 90px
  }
  .inbox-center a {
      width: 400px
  }
}

@media (min-width:1024px) {
  .app-search .form-control:focus {
      width: 300px
  }
}

@media (min-width:768px) {
  #page-wrapper {
      position: inherit;
      margin: 0
  }
  .navbar-default {
      position: relative;
      width: 100%;
      top: 0
  }
  .fix-header .navbar-static-top {
      position: fixed
  }
  .fix-header .sidebar {
      padding-top: 60px
  }
  .sidebar {
      z-index: 10;
      position: relative;
      width: 100%
  }
  .sidebar .nav-second-level {
      box-shadow: 0 10px 30px rgba(0, 0, 0, .1)
  }
  .sidebar #side-menu>li:hover a {
      background: #f7fafc
  }
  #wrapper.fix-top .sidebar {
      position: fixed;
      top: 0;
      transition: .5s ease-in
  }
  #wrapper.fix-top #page-wrapper {
      padding-top: 95px
  }
  .navbar-top-links .dropdown-alerts,
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks {
      margin-left: auto
  }
  .mail_listing {
      border-left: 1px solid rgba(120, 130, 140, .13);
      padding-left: 20px
  }
  .inbox-panel {
      padding-right: 20px
  }
  .top-minus {
      margin-top: -62px;
      float: right
  }
  .content-wrapper.fix-sidebar .navbar-left,
  .fix-sidebar.content-wrapper .navbar-left {
      margin-left: 60px!important
  }
  .content-wrapper.fix-sidebar .sidebar,
  .fix-sidebar.content-wrapper .sidebar {
      position: absolute
  }
  .lobipanel .panel-heading .dropdown .dropdown-menu>li>a {
      color: #fff
  }
}

@media (max-width:1024px) {
  .b-r-none {
      border-right: 0
  }
  .carousel-inner h3 {
      height: 90px;
      overflow: hidden
  }
  .inbox-center a {
      width: 300px
  }
}

@media (max-width:767px) {
  .navbar-top-links {
      display: inline-block
  }
  .navbar-top-links .profile-pic img {
      margin-right: 0
  }
  .top-left-part {
      width: 60px
  }
  .navbar-top-links li:last-child {
      margin-right: 0
  }
  .navbar-top-links .dropdown-alerts,
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks {
      width: 260px
  }
  .row-in-br {
      border-right: 0;
      border-bottom: 1px solid rgba(120, 130, 140, .13)
  }
  .bg-title .breadcrumb {
      float: 'left';
      margin-top: 0;
      margin-bottom: 10px
  }
  ul.timeline:before {
      left: 40px
  }
  ul.timeline>li>.timeline-panel {
      width: calc(100% - 90px)
  }
  ul.timeline>li>.timeline-badge {
      top: 16px;
      left: 15px;
      margin-left: 0
  }
  ul.timeline>li>.timeline-panel {
      float: right
  }
  ul.timeline>li>.timeline-panel:before {
      right: auto;
      left: -15px;
      border-right-width: 15px;
      border-left-width: 0
  }
  ul.timeline>li>.timeline-panel:after {
      right: auto;
      left: -14px;
      border-right-width: 14px;
      border-left-width: 0
  }
  .wizard-steps>li {
      display: block
  }
  .dropdown .dropdown-tasks,
  .dropdown .mailbox {
      left: -94px
  }
  .fix-header .navbar-static-top {
      position: fixed;
      top: 0;
      width: 100%
  }
  .fix-header #page-wrapper {
      margin-top: 60px
  }
  .fix-header .sidebar {
      position: fixed;
      height: 350px;
      top: 60px;
      z-index: 100;
      overflow: auto!important;
      box-shadow: 0 10px 35px rgba(0, 0, 0, .2)
  }
  .mega-dropdown-menu {
      height: 340px;
      overflow: auto
  }
  .left-aside {
      position: relative;
      width: 100%;
      border: 0
  }
  .right-aside {
      margin-left: 0
  }
  .chat-main-box .chat-left-aside {
      left: -250px;
      transition: .5s ease-in;
      background: #fff
  }
  .chat-main-box .chat-left-aside.open-pnl {
      left: 0
  }
  .chat-main-box .chat-left-aside .open-panel {
      display: block
  }
  .chat-main-box .chat-right-aside {
      margin: 0
  }
  .table-responsive.pro-rd {
      border: none
  }
  #msform fieldset,
  .login-register,
  .step-register {
      position: relative
  }
}

@media (max-width:480px) {
  .vtabs .tabs-vertical {
      width: auto
  }
  .stat-item {
      padding-right: 0
  }
  .login-box {
      width: 100%
  }
  .pro-content .pro-list-details {
      height: 100px;
      border-right: none
  }
  .pro-list-info ul.pro-info li {
      padding: 10px 0
  }
  .pro-list-info ul.pro-info {
      padding-left: 0
  }
  .pro-agent .agent-img {
      padding-top: 3px
  }
  .pro-agent .agent-name {
      padding: 2px 0 10px 15px
  }
}

@media (max-width:991px) {
  .line-steps .step-title:after,
  .line-steps .step-title:before {
      content: none
  }
  .numbered-bg .step-info,
  .numbered-bg .step-title {
      padding-right: 0
  }
}

.navbar-static-top {
  padding: 0
}

.navbar-static-top .dropdown-toggle::after {
  display: none
}

.mega-dropdown .mega-dropdown-menu>li {
  float: 'left';
  width: 100%
}

#side-menu {
  display: block;
  transition: .5 easy-out
}

#side-menu .nav {
  flex-direction: column
}

a.btn:not([href]):not([tabindex]) {
  color: #fff;
  font-size: 14px;
  cursor: pointer
}

.btn {
  font-size: 14px;
  padding: .8rem 1.2rem
}

a.btn-default:not([href]):not([tabindex]) {
  color: #686868
}

.btn-group.show {
  display: inline-block!important
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px
}

.btn-xs {
  padding: 1px 8px;
  font-size: 11px
}

.btn-circle {
  padding: 6px 0
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate
}

.btn-group-justified .btn,
.btn-group-justified .btn-group {
  display: table-cell;
  float: none;
  width: 1%
}

.grid-stack {
  width: 100%
}

.mail_listing .media {
  display: block
}

.customtab.nav-tabs .nav-link {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  color: dimgrey
}

.vtabs .tabs-vertical li a.active,
.vtabs .tabs-vertical li a.active:focus,
.vtabs .tabs-vertical li a.active:hover {
  background: #ff6849;
  border: 0;
  border-right: 2px solid #ff6849;
  margin-right: -1px;
  color: #fff
}

.customvtab .tabs-vertical li a.active,
.customvtab .tabs-vertical li a.active:focus,
.customvtab .tabs-vertical li a.active:hover {
  background: #fff;
  border: 0;
  border-right: 2px solid #ff6849;
  margin-right: -1px;
  color: #2b2b2b
}

.customtab3.nav-tabs .nav-link {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  color: dimgrey
}

.customtab3.nav-tabs .nav-link.active,
.customtab3.nav-tabs .nav-link.active:focus,
.customtab3.nav-tabs .nav-link:hover {
  border-bottom: 0px solid #01c0c8;
  color: #2b2b2b;
  border-radius: 75px
}

.vtabs .tabs-vertical li a.active,
.vtabs .tabs-vertical li a.active:focus,
.vtabs .tabs-vertical li a.active:hover {
  background: #ff6849;
  border: 0;
  border-right: 2px solid #ff6849;
  margin-right: -1px;
  color: #fff
}

.customvtab .tabs-vertical li a.active,
.customvtab .tabs-vertical li a.active:focus,
.customvtab .tabs-vertical li a.active:hover {
  background: #fff;
  border: 0;
  border-right: 2px solid #ff6849;
  margin-right: -1px;
  color: #2b2b2b
}

.customtab2 li .nav-link.active,
.customtab2 li .nav-link.active:focus,
.customtab2 li .nav-link.active:hover {
  background: #ff6849;
  border: 0 solid #ff6849;
  color: #fff
}

.customtab2.nav-tabs .nav-link {
  border: 0
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
  background: #ff6849
}

span.caret {
  display: none
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px
}

.list-inline>li {
  display: inline-block
}

.modal-header {
  display: block
}

.modal-header .modal-title {
  margin-top: 0
}

.popover,
.popover-title,
.tooltip {
  font-family: Poppins, sans-serif;
  font-size: 13px
}

.popover-title {
  margin-top: 0
}

.custom-select {
  height: calc(4.25rem)
}

.fileinput .form-control {
  width: 100%;
  position: relative
}

.fileinput-new .input-group-addon {
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 10!important
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px
}

.custom-control {
  padding-left: 20px
}

.custom-control-indicator {
  width: 15px;
  height: 15px
}

.custom-file {
  height: 30px;
  width: 100%
}

.custom-file .custom-file-control,
.custom-file .custom-file-control::before {
  height: 35px
}

select.form-control:not([size]):not([multiple]) {
  height: calc(3.85rem)
}

.datepicker td,
.datepicker th,
.table-condensed td,
.table-condensed th {
  padding: 5px
}

.note-popover {
  display: none
}

.note-editor {
  border: 0
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: none
}

.modal-backdrop.in {
  opacity: .5
}

a.fc-event:not([href]):not([tabindex]) {
  color: #fff
}

.dataTables_wrapper {
  display: block
}

.dataTables_wrapper label {
  display: inline-block
}

.modal-img {
  width: 100px;
  height: 100px;
  margin-top: 20px;
  border-color: transparent!important;
}

.agendaDay {
  font-family: monospace;
  font-size: 16px
}

.navbar-header {
  background: #40D2BE;
}
.navbar-top-links > li > a {
  color: #ffffff;
}
/*Right panel*/
.right-sidebar .rpanel-title {
  background: #01c0c8;
}
/*Bread Crumb*/
.bg-title .breadcrumb .active {
  color: #01c0c8;
}
/*Sidebar*/
.sidebar {
  background: #ffffff;
}
.sidebar .label-custom {
  background: #01c0c8;
}
#side-menu li a {
  color: #54667a;
}
#side-menu li a {
  color: #54667a;
  border-left: 0px solid #fff;
}
.nav-second-level > li > a:hover,
.nav-second-level > li > a:focus {
  background-color: #f7fafc !important;
}
#side-menu > li > a:hover,
#side-menu > li > a:focus {
  background-color: #f7fafc !important;
}
#side-menu > li > a.active {
  border-bottom: 2px solid #01c0c8;
  color: #2b2b2b;
  font-weight: 500;
}
#side-menu > li > a.active i {
  color: #01c0c8;
}
#side-menu ul > li > a:hover {
  color: #01c0c8;
  background: none;
}
#side-menu ul > li > a.active {
  color: #2b2b2b;
  font-weight: 500;
}
.sidebar #side-menu .user-pro .nav-second-level a:hover {
  color: #01c0c8;
}
.fix-sidebar .top-left-part {
  background: #01c0c8;
}
#side-menu .nav-second-level li:hover > a {
  color: #01c0c8;
}
/*themecolor*/
.bg-theme {
  background-color: #fb9678 !important;
}
.bg-theme-dark {
  background-color: #01c0c8 !important;
}
/*Chat widget*/
.chat-list .odd .chat-text {
  background: #01c0c8;
}
/*Button*/
.btn-custom {
  background: #01c0c8;
  border: 1px solid #01c0c8;
  color: #ffffff;
}
.btn-custom:hover {
  background: #01c0c8;
  opacity: 0.8;
  color: #ffffff;
  border: 1px solid #01c0c8;
}
/*Custom tab*/
.customtab li.active a,
.customtab li.active a:hover,
.customtab li.active a:focus {
  border-bottom: 2px solid #01c0c8;
  color: #01c0c8;
}
.tabs-vertical li.active a,
.tabs-vertical li.active a:hover,
.tabs-vertical li.active a:focus {
  background: #01c0c8;
  border-right: 2px solid #01c0c8;
}
/*Nav-pills*/
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background: #01c0c8;
  color: #ffffff;
}

.scrollbar-deep-purple::-webkit-scrollbar-track {
  box-shadow : inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }

.scrollbar-deep-purple::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

.scrollbar-deep-purple::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow : inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #C997EB; }

.scrollbar-cyan::-webkit-scrollbar-track  {

  /*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);*/
  background-color: transparent;
  opacity: 0.9;
  border-radius: 10px; }

.scrollbar-cyan::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
  border-radius: 10px;}

.scrollbar-cyan::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eeeff0; }

.scrollbar-dusty-grass::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }

.scrollbar-dusty-grass::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

.scrollbar-dusty-grass::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%); }

.scrollbar-ripe-malinka::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }

.scrollbar-ripe-malinka::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

.scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%); }

.bordered-deep-purple::-webkit-scrollbar-track {
  box-shadow: none;
  border: 1px solid #512da8; }

.bordered-deep-purple::-webkit-scrollbar-thumb { 
  box-shadow: none; }

.bordered-cyan::-webkit-scrollbar-track {
  box-shadow: none;
  border: 1px solid #00bcd4; }

.bordered-cyan::-webkit-scrollbar-thumb { 
  box-shadow: none; 
}

.square::-webkit-scrollbar-track {
  border-radius: 0 !important; }

.square::-webkit-scrollbar-thumb {
  border-radius: 0 !important; }

.thin::-webkit-scrollbar {
  width: 6px; }

.example-1 {
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 550px; }

.example-2 {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 550px;
  border-color: transparent!important;}

.example-3 {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 560px;
  border-color: transparent!important;}


@media only screen and (min-height: 500px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 290px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 235px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 600px){
  .example-4 {
       box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
      border-color: transparent!important;
      min-height: 50px;
      max-height: 390px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 335px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 700px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 490px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 435px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 800px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 590px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 535px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 900px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 690px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 635px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1000px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 790px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 735px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1100px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 890px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 835px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1200px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 990px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 935px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1300px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1090px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1035px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1400px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1190px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1135px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1500px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1290px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1235px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1600px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1390px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1335px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1700px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1490px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1435px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1800px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1590px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1535px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 1900px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1690px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1635px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

@media only screen and (min-height: 2000px){
  .example-4 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1790px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .example-5 {
      border-color: transparent!important;
      min-height: 50px;
      max-height: 1735px;
      border-radius: 10px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}
.task {
  cursor : default
}

.task-done {
  text-decoration: line-through
}

.background-light {
  background-color: #F7FAFC
}

.content {
  min-height: calc(100vh - 330px);
}

.modalMaterial {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius : 10px;
}

@media only screen and (min-height: 500px){
  .calendarDiv {
    height: 400px!important;
  }
}

@media only screen and (min-height: 700px){
  .calendarDiv {
      min-height: 560px;
      max-height: 560px;
  }
}

@media only screen and (min-height: 1000px){
  .calendarDiv {
      min-height: 760px;
      max-height: 760px;
  }
}

.calendar-events {
  padding: 8px 10px;
  /* cursor: move; */
}


.marginPrimary {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13)
}

.noBorder {
  border-radius : 0!important;
}
.nested {
  padding-left : 2em!important;
  padding-right : 1em!important;
  padding-top : 5px!important;
  padding-bottom : 5px!important;
}

.nested-second {
  padding-left : 3.5em!important;
  padding-top : 5px!important;
  padding-bottom : 5px!important;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}


.subLabel{
  margin-left: 2.2rem !important;
  margin-top: -10px!important;
}

.disabledTextArea {
  pointer-events: none!important;
  opacity: 0.85
}

.disabled-pointer-events {
  pointer-events: none!important;
}

.p-card {
  padding: 10px 20px 20px 20px!important
}
